/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  Theme,
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  Tooltip,
  Radio,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import hardCodeData from 'src/utils/hardcodeData'
import { PermissionCodeAccess } from 'src/utils/constants'
import { useFormik } from 'formik'
import {
  Close,
  Check,
  SwapHoriz,
  Save,
  Delete,
  Visibility,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import {
  useDeleteBuyerBcoFile,
  useUpdateBuyerBco,
  useUploadBuyerBcoFile,
} from 'src/graphql/operations/mutations/buyer'
import {
  BusinessBuyerRequestType,
  BusinessFileLinkTypes,
  BuyerBcoDataResponseTypes,
} from 'src/graphql/models/buyer'
import * as Yup from 'yup'
import { useQuery } from '@apollo/client'
import {
  GET_BUSINESS_FILE_LIST,
  GET_BUYER_BCO_DATA,
} from 'src/graphql/operations/queries/buyer'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { notistackOptions } from 'src/configs/notistackOptions'
import { ConfirmDialog } from 'src/components/Dialogs'
import { AbilityContext } from 'src/context/Can'
import BcoFilesTable from './BcoFiles'
import BCOPreview from './BCOPreview'

interface BuyerComplianceInterface {
  buyerBusinessId: string
  buyerData?: BusinessBuyerRequestType | undefined
  clientCountry?: string | undefined
}

const acceptUpload =
  'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'

const acceptUploadFinancial =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const MAX_SIZE_FILE = 104857600
const BuyerCompliance: React.FC<BuyerComplianceInterface> = ({
  buyerBusinessId,
  buyerData,
  clientCountry,
}) => {
  const initialValueData = {
    bcoBuyerType: buyerData?.bCOBuyerType || 'Active',
    bcoEnabled: buyerData?.bCOEnabled || false,
    bcoAgenciesCertified: buyerData?.bCOAgenciesCertified || 0,
    bcoOfficerBackgroundChecks: buyerData?.bCOOfficerBackgroundChecks || 0,
    bcoAttestation: buyerData?.bCOAttestation || 0,
    bcoFinancials: buyerData?.bCOFinancials || 0,
    bcoComplianceNotes: buyerData?.bCOComplianceNotes || '',
    hasNoAgencyInformation: buyerData?.hasNoAgencyInformation || false,
  }
  const ability = useContext(AbilityContext)
  const [fileTypeSelected, setfileTypeSelected] = useState('')
  const notifyWarning = notistackOptions('warning')

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [fileTypeRemove, setfileTypeRemove] = useState('')

  const [confirmationTitle, setConfirmationTitle] = useState<string>('')
  const [confirmationMessage, setConfirmationMessage] = useState<string>('')
  const [showBCOForm, setShowBCOForm] = useState(false)

  const { data: getBuyerBcoData, loading: loadingBuyerBcoData } =
    useQuery<BuyerBcoDataResponseTypes>(GET_BUYER_BCO_DATA, {
      variables: {
        buyerId: buyerBusinessId,
      },
    })

  const { data: getFinancialFiles } = useQuery<BusinessFileLinkTypes>(
    GET_BUSINESS_FILE_LIST,
    {
      variables: {
        businessId: buyerBusinessId,
        fileType: 'financial',
      },
    }
  )

  const { data: getAttestationFiles } = useQuery<BusinessFileLinkTypes>(
    GET_BUSINESS_FILE_LIST,
    {
      variables: {
        businessId: buyerBusinessId,
        fileType: 'cco-attestation',
      },
    }
  )

  const handleBCOFormToggle = () => {
    setShowBCOForm(!showBCOForm)
  }

  const BuyerComplianceSchema = Yup.object().shape({
    bcoBuyerType: Yup.string().nullable().required('Required'),
    bcoAgenciesCertified: Yup.string().nullable().required('Required'),
    bcoOfficerBackgroundChecks: Yup.string().nullable().required('Required'),
    bcoAttestation: Yup.string().nullable().required('Required'),
    bcoFinancials: Yup.string().nullable().required('Required'),
  })

  const useStylesTooltip = makeStyles((theme: Theme) => ({
    tooltip: {
      maxWidth: 400,
    },
  }))

  const HtmlTooltip = ({
    children,
    title,
    placement,
  }: {
    children: React.ReactElement<any, any>
    title: React.ReactNode
    placement?: 'left-start'
  }) => {
    const classes = useStylesTooltip()
    return (
      <Tooltip title={title} className={classes.tooltip} placement={placement}>
        {children}
      </Tooltip>
    )
  }

  const { uploadBuyerBcoFile, loading: fileUploading } = useUploadBuyerBcoFile({
    onCompleted: (data: any) => {
      if (data) {
        enqueueSnackbar(
          'File uploaded successfully',
          notistackOptions('success')
        )
      } else {
        enqueueSnackbar(
          'Error while trying to upload the file',
          notistackOptions('warning')
        )
      }
    },
  })

  const { deleteBuyerBcoFile } = useDeleteBuyerBcoFile({
    onCompleted: (data: any) => {
      if (data) {
        enqueueSnackbar(
          'Files removed successfully.',
          notistackOptions('success')
        )
      } else {
        enqueueSnackbar('No one file was removed.', notistackOptions('warning'))
      }
    },
  })

  const handleRemoveFile = (fileType: string): void => {
    deleteBuyerBcoFile({
      variables: {
        businessId: buyerBusinessId,
        fileType,
      },
      refetchQueries: ['GetBusinessFileList'],
    })
    setfileTypeRemove('')
    setOpenRemoveDialog(false)
  }

  const handleClose = () => {
    setOpenRemoveDialog(false)
    setfileTypeRemove('')
  }

  const handleRemove = () => {
    handleRemoveFile(fileTypeRemove)
  }

  const onRemove = (fileType: string) => {
    setfileTypeRemove(fileType)
  }

  const handleFileUpload = (acceptedFiles: File[], fileType: string) => {
    if (acceptedFiles && acceptedFiles.length) {
      setfileTypeSelected(fileType)

      uploadBuyerBcoFile({
        variables: {
          businessId: buyerBusinessId,
          fileType,
          inputFileName: acceptedFiles[0].name || 'testfile',
          file: acceptedFiles[0],
        },
        refetchQueries: ['GetBusinessFileList'],
      })
    }
  }

  const handleFileRejected = () => {
    enqueueSnackbar('Error while trying to upload the file', notifyWarning)
  }

  const { enqueueSnackbar } = useSnackbar()

  const handleAddOrUpdateCompleted = (data: any) => {
    if (data) {
      const text = buyerBusinessId ? 'Update' : 'Create'
      enqueueSnackbar(`${text} buyer successful`, {
        variant: 'success',
      })
    }
  }
  const {
    updateBuyerBcoParameters,
    loading: addOrUpdateBuyerComplianceLoading,
  } = useUpdateBuyerBco(handleAddOrUpdateCompleted)

  const [initialValues] = useState<any>(initialValueData)
  const loading = false
  const hasCompliancePermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_COMPLIANCE_PERMISSION,
    'any'
  )
  const BuyerBusinessForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: BuyerComplianceSchema,
    onSubmit: (values, { setSubmitting }) => {
      const buyerRequest = {
        bcoBuyerType: values.bcoBuyerType || 'Active',
        bcoEnabled: values.bcoEnabled || false,
        bcoAgenciesCertified: values.bcoAgenciesCertified || 0,
        bcoOfficerBackgroundChecks: values.bcoOfficerBackgroundChecks || 0,
        bcoAttestation: values.bcoAttestation || 0,
        bcoFinancials: values.bcoFinancials || 0,
        bcoComplianceNotes: values.bcoComplianceNotes || null,
        hasNoAgencyInformation: values.hasNoAgencyInformation || false,
      }
      if (buyerBusinessId) {
        updateBuyerBcoParameters({
          variables: {
            request: {
              buyerId: buyerBusinessId,
              ...buyerRequest,
            },
          },
          refetchQueries: [
            'GetClient',
            'GetBuyer',
            'GetBusinessFileList',
            'GetBuyerBcoData',
          ],
        })
      }
    },
  })

  useEffect(() => {
    if (!openRemoveDialog && fileTypeRemove) {
      const fileName =
        fileTypeRemove === 'financial'
          ? 'Financial Statements'
          : 'Licencing Attestation'

      setConfirmationMessage(
        `All of ${fileName} files will be removed. Do you to continue?`
      )

      setConfirmationTitle(`Remove ${fileName}`)
      setOpenRemoveDialog(true)
    }
  }, [confirmationMessage, fileTypeRemove, openRemoveDialog])

  return (
    <>
      <form onSubmit={BuyerBusinessForm.handleSubmit}>
        <Box mb={6}>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4} md={4}>
              <Typography variant="h6">Buyer Compliance Overview</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={!hasCompliancePermission}
                    onChange={BuyerBusinessForm.handleChange}
                    name="bcoEnabled"
                    value={BuyerBusinessForm.values.bcoEnabled}
                    checked={BuyerBusinessForm.values.bcoEnabled}
                  />
                }
                label="BCO Enabled"
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} />
            <Grid item xs={6} sm={4} md={4}>
              <FormControl disabled={true} component="fieldset">
                <FormLabel
                  style={{ fontSize: '0.75rem', fontFamily: 'inherit' }}
                >
                  Buyer Type
                </FormLabel>
                <RadioGroup
                  aria-label="bcoBuyerType"
                  name="bcoBuyerType"
                  value={BuyerBusinessForm.values.bcoBuyerType}
                  onChange={(option) => {
                    BuyerBusinessForm.setFieldValue(
                      'bcoBuyerType',
                      option.target.value,
                      false
                    )
                  }}
                  row
                >
                  <FormControlLabel
                    value="Active"
                    control={
                      <Radio
                        disabled={!hasCompliancePermission}
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="Passive"
                    control={
                      <Radio
                        disabled={!hasCompliancePermission}
                        color="primary"
                      />
                    }
                    label="Passive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={!hasCompliancePermission}
                    onChange={BuyerBusinessForm.handleChange}
                    name="hasNoAgencyInformation"
                    value={BuyerBusinessForm.values.hasNoAgencyInformation}
                    checked={BuyerBusinessForm.values.hasNoAgencyInformation}
                  />
                }
                label="No agency information provided"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">BCO Metrics</Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4} md={4}>
              <TextField
                fullWidth
                label="Agencies Certified"
                select
                disabled={!hasCompliancePermission}
                name="bcoAgenciesCertified"
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.bcoAgenciesCertified}
                value={BuyerBusinessForm.values.bcoAgenciesCertified}
                helperText={
                  <>{BuyerBusinessForm.errors.bcoAgenciesCertified}</>
                }
              >
                {hardCodeData.getAgenciesCertified().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <HtmlTooltip title={option.description}>
                      <Typography style={{ width: '100%' }}>
                        {option.icon ? (
                          option.icon === 'check' ? (
                            <Check
                              color="primary"
                              fontSize="small"
                              style={{ height: '1rem' }}
                            />
                          ) : option.icon === 'arrows-swap' ? (
                            <SwapHoriz
                              fontSize="small"
                              style={{ height: '1rem', color: 'gold' }}
                            />
                          ) : option.icon === 'close' ? (
                            <Close style={{ height: '1rem', color: 'red' }} />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}{' '}
                        {option.label}
                      </Typography>
                    </HtmlTooltip>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={1} sm={1} md={1} />
            <Grid item xs={6} sm={4} md={4}>
              <TextField
                fullWidth
                label="Officer Background Checks Performed"
                select
                name="bcoOfficerBackgroundChecks"
                disabled={!hasCompliancePermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.bcoOfficerBackgroundChecks}
                value={BuyerBusinessForm.values.bcoOfficerBackgroundChecks}
                helperText={
                  <>{BuyerBusinessForm.errors.bcoOfficerBackgroundChecks}</>
                }
              >
                {hardCodeData.getBackgroundChecks().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <HtmlTooltip title={option.description}>
                      <Typography style={{ width: '100%' }}>
                        {option.icon ? (
                          option.icon === 'check' ? (
                            <Check color="primary" style={{ height: '1rem' }} />
                          ) : option.icon === 'arrows-swap' ? (
                            <SwapHoriz
                              style={{ height: '1rem', color: 'gold' }}
                            />
                          ) : option.icon === 'close' ? (
                            <Close style={{ height: '1rem', color: 'red' }} />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        {option.label}
                      </Typography>
                    </HtmlTooltip>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4} md={4}>
              <Box>
                <TextField
                  fullWidth
                  label="Licensing Attestation"
                  select
                  name="bcoAttestation"
                  disabled={!hasCompliancePermission}
                  onChange={BuyerBusinessForm.handleChange}
                  error={!!BuyerBusinessForm.errors.bcoAttestation}
                  value={BuyerBusinessForm.values.bcoAttestation}
                  helperText={<>{BuyerBusinessForm.errors.bcoAttestation}</>}
                >
                  {hardCodeData.getLicenseAttestation().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <HtmlTooltip title={option.description}>
                        <Typography style={{ width: '100%' }}>
                          {option.icon ? (
                            option.icon === 'check' ? (
                              <Check
                                color="primary"
                                style={{ height: '1rem' }}
                              />
                            ) : option.icon === 'arrows-swap' ? (
                              <SwapHoriz
                                style={{ height: '1rem', color: 'gold' }}
                              />
                            ) : option.icon === 'close' ? (
                              <Close style={{ height: '1rem', color: 'red' }} />
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {option.label}
                        </Typography>
                      </HtmlTooltip>
                    </MenuItem>
                  ))}
                </TextField>

                <UploadDragDrop
                  size="small"
                  linkText="Upload"
                  text="Licencing Attestation"
                  uploading={
                    fileUploading && fileTypeSelected === 'cco-attestation'
                  }
                  uploadDisabled={
                    loadingBuyerBcoData || !hasCompliancePermission
                  }
                  onDrop={(acceptedFiles) => {
                    handleFileUpload(acceptedFiles, 'cco-attestation')
                  }}
                  maxSize={MAX_SIZE_FILE}
                  onDropRejected={handleFileRejected}
                  accept={acceptUpload}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  Maximum allowed file size is <strong>100MB</strong>
                </Typography>

                <BcoFilesTable
                  data={getAttestationFiles?.getBusinessFileList || []}
                  buyerId={buyerBusinessId}
                />
              </Box>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Box style={{ marginTop: '0.5rem' }}>
                {hasCompliancePermission && (
                  <IconButton onClick={(): void => onRemove('cco-attestation')}>
                    <Delete />
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box>
                <TextField
                  fullWidth
                  label="Financial Statements"
                  select
                  name="bcoFinancials"
                  disabled={!hasCompliancePermission}
                  onChange={BuyerBusinessForm.handleChange}
                  error={!!BuyerBusinessForm.errors.bcoFinancials}
                  value={BuyerBusinessForm.values.bcoFinancials}
                  helperText={<>{BuyerBusinessForm.errors.bcoFinancials}</>}
                >
                  {hardCodeData.getFinancialStatements().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <HtmlTooltip title={option.description}>
                        <Typography style={{ width: '100%' }}>
                          {option.icon ? (
                            option.icon === 'check' ? (
                              <Check
                                color="primary"
                                style={{ height: '1rem' }}
                              />
                            ) : option.icon === 'arrows-swap' ? (
                              <SwapHoriz
                                style={{ height: '1rem', color: 'gold' }}
                              />
                            ) : option.icon === 'close' ? (
                              <Close style={{ height: '1rem', color: 'red' }} />
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {option.label}
                        </Typography>
                      </HtmlTooltip>
                    </MenuItem>
                  ))}
                </TextField>

                <UploadDragDrop
                  size="small"
                  linkText="Upload"
                  text="Financial"
                  uploading={fileUploading && fileTypeSelected === 'financial'}
                  uploadDisabled={
                    loadingBuyerBcoData || !hasCompliancePermission
                  }
                  onDrop={(acceptedFiles) => {
                    handleFileUpload(acceptedFiles, 'financial')
                  }}
                  maxSize={MAX_SIZE_FILE}
                  onDropRejected={handleFileRejected}
                  accept={acceptUploadFinancial}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  Maximum allowed file size is <strong>100MB</strong>
                </Typography>

                <BcoFilesTable
                  data={getFinancialFiles?.getBusinessFileList || []}
                  buyerId={buyerBusinessId}
                />
              </Box>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Box style={{ marginTop: '0.5rem' }}>
                {hasCompliancePermission && (
                  <IconButton onClick={(): void => onRemove('financial')}>
                    <Delete />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Additional Information</Typography>
          <Grid container spacing={4}>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={4}
                label="Compliance Notes"
                name="bcoComplianceNotes"
                disabled={!hasCompliancePermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.bcoComplianceNotes}
                value={BuyerBusinessForm.values.bcoComplianceNotes}
                helperText={<>{BuyerBusinessForm.errors.bcoComplianceNotes}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="flex-end" my={2}>
          <Button
            startIcon={
              !addOrUpdateBuyerComplianceLoading ? (
                <Visibility />
              ) : (
                <CircularProgress size={16} />
              )
            }
            variant="contained"
            onClick={() => {
              setShowBCOForm(true)
            }}
            color="primary"
            disabled={
              loading ||
              addOrUpdateBuyerComplianceLoading ||
              !hasCompliancePermission ||
              loadingBuyerBcoData
            }
            style={{ marginRight: '1rem' }}
          >
            Preview
          </Button>

          <Button
            startIcon={
              !addOrUpdateBuyerComplianceLoading ? (
                <Save />
              ) : (
                <CircularProgress size={16} />
              )
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              loading ||
              addOrUpdateBuyerComplianceLoading ||
              !hasCompliancePermission
            }
          >
            Save
          </Button>
        </Box>
      </form>
      <BCOPreview
        buyerBCOData={getBuyerBcoData?.getBuyerBcoData}
        onClose={handleBCOFormToggle}
        open={showBCOForm}
        country={buyerData?.countryCode || ''}
      />
      <ConfirmDialog
        open={openRemoveDialog}
        title={confirmationTitle}
        description={
          <Box mb={3}>
            <Typography variant="body2" color="textSecondary">
              {confirmationMessage}
            </Typography>
          </Box>
        }
        closeName="Cancel"
        confirmName="Continue"
        onClose={handleClose}
        onConfirm={handleRemove}
      />
    </>
  )
}

export default BuyerCompliance
