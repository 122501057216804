import React, { useState } from 'react'
import { Box, FormControlLabel, Grid, Skeleton, Switch } from '@mui/material'
import { State } from '@progress/kendo-data-query'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  DataTable,
  ModalDialog,
  Typography,
  renderCheckbox,
} from 'everchain-uilibrary'
import {
  addOrRemoveFileUnmaskWithColumns,
  getSellerUploadTemplateFileUnmaskBySeller,
} from 'src/infra/api/services/fileUnmask'
import {
  FileUnmaskColumnsRequest,
  FileUnmaskWithColumnsRequest,
  SellerUploadTemplateFileUnmaskDataResponse,
} from 'src/infra/api/models/fileUnmask'
import { getPortfolioTemplateFileMap } from 'src/infra/api/services/portfolio'
import { PortfolioTemplateFileMapResponse } from 'src/infra/api/models/portfolio'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'

interface FileUnmaskListProps {
  sellerId?: string
}

const FileUnmaskList: React.FC<FileUnmaskListProps> = ({ sellerId }) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [editTemplateId, setEditTemplateId] = useState<number>()
  const [columnsRequest, setColumnsRequest] = useState<
    FileUnmaskColumnsRequest[]
  >([])
  const [unmaskWithColumnsRequest, setUnmaskWithColumnsRequest] =
    useState<FileUnmaskWithColumnsRequest>()
  const [unmaksEntireFile, setUnmaksEntireFile] = useState<boolean>(false)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const gridStateEdit = {
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  }

  const gridColumns: any[] = [
    {
      field: 'sellerUploadTemplateId',
      title: 'Template ID',
      width: 180,
      show: true,
    },
    {
      field: 'sellerUploadTemplateName',
      title: 'Template Name',
      width: 180,
      show: true,
    },
    {
      field: '',
      title: 'Unmasked Columns',
      width: 180,
      show: true,
      render: (props: any) => {
        const joinedColumns = props.dataItem?.fileUnmask?.allColumnsSelected
          ? 'All'
          : props.dataItem?.fileUnmask?.fileUnmaskColumns
              .map((x: any) => x.columnName)
              .join(', ')

        return <td>{joinedColumns}</td>
      },
    },
  ]

  const gridColumnsEdit: any[] = [
    {
      field: 'headerName',
      title: 'Column Name',
      width: 180,
      show: true,
    },
    {
      title: 'Unmasked',
      width: 180,
      show: true,
      render: (props: any) =>
        renderCheckbox(
          unmaksEntireFile,
          checkUnmaskedChecked(props.dataItem?.headerName),
          (checked) =>
            handleOnChangeUnmasked(checked, props.dataItem?.headerName)
        ),
    },
  ]

  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const checkUnmaskedChecked = (columnName: string): boolean => {
    return (
      columnsRequest.some((x) => x.unmask && x.columnName === columnName) ||
      (!!fileUnmaskData?.sellerUploadTemplateFileUnmask.some(
        (template) =>
          template.sellerUploadTemplateId === editTemplateId &&
          template.fileUnmask?.fileUnmaskColumns?.some(
            (column) => column.columnName === columnName
          )
      ) &&
        !columnsRequest.some((x) => !x.unmask && x.columnName === columnName))
    )
  }

  const handleOnChangeUnmasked = (checked: boolean, columnName: string) => {
    setColumnsRequest((prev) => {
      const columnExists = prev.some((item) => item.columnName === columnName)

      if (columnExists) {
        return prev.map((item) =>
          item.columnName === columnName ? { ...item, unmask: checked } : item
        )
      } else {
        return [...prev, { columnName, unmask: checked }]
      }
    })
  }

  const handleSaveEditUnmask = () => {
    if (sellerId && editTemplateId) {
      setUnmaskWithColumnsRequest({
        sellerId: sellerId,
        sellerUploadTemplateId: editTemplateId,
        allColumnsSelected: unmaksEntireFile,
        unmaskColumnsRequest: columnsRequest,
      })
    }
  }

  const { data: fileUnmaskData, isFetching: fileUnmaskDataLoading } =
    useCustomQuery<SellerUploadTemplateFileUnmaskDataResponse>(
      ['getSellerUploadTemplateFileUnmaskBySeller', sellerId, gridState],
      async () => {
        return getSellerUploadTemplateFileUnmaskBySeller(
          sellerId,
          JSON.stringify(gridState)
        )
      },
      {
        enabled: !!sellerId,
        cacheTime: 0,
      }
    )

  const { isFetching: addOrRemoveFileUnmaskLoading } = useCustomQuery(
    ['addOrRemoveFileUnmaskWithColumns', unmaskWithColumnsRequest],
    async () => {
      if (unmaskWithColumnsRequest) {
        return addOrRemoveFileUnmaskWithColumns(unmaskWithColumnsRequest)
          .then(() => {
            enqueueSnackbar('Unmasked columns updated.', notifySuccess)
            setOpenEditModal(false)
            reactQueryClient.refetchQueries({
              queryKey: ['getSellerUploadTemplateFileUnmaskBySeller'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error when update unmasked columns.', notifyError)
          })
          .finally(() => {
            setColumnsRequest([])
          })
      }
    },
    {
      enabled: !!unmaskWithColumnsRequest,
      cacheTime: 0,
    }
  )

  const { data: templateFileMap, isFetching: templateFileMapLoading } =
    useCustomQuery<PortfolioTemplateFileMapResponse>(
      ['getPortfolioTemplateFileMap', editTemplateId, openEditModal],
      async () => {
        return getPortfolioTemplateFileMap(editTemplateId)
      },
      {
        enabled: !!editTemplateId && openEditModal,
        cacheTime: 0,
      }
    )

  return (
    <Box mb={12}>
      <Typography variant="h6">Portfolio Data Manager</Typography>
      <Grid mt={2} container spacing={4}>
        <Grid item xs={12} lg={12}>
          <DataTable
            style={{
              cursor: 'pointer',
            }}
            isLoading={fileUnmaskDataLoading}
            isFetching={fileUnmaskDataLoading}
            gridColumns={gridColumns}
            gridState={gridState}
            data={fileUnmaskData?.sellerUploadTemplateFileUnmask}
            sortable
            pageable
            onRowClick={(row) => {
              setEditTemplateId(row.dataItem.sellerUploadTemplateId)
              setUnmaksEntireFile(row.dataItem?.fileUnmask?.allColumnsSelected)
              setOpenEditModal(true)
            }}
            total={fileUnmaskData?.total}
            onDataStateChange={(e: any) => {
              setGridState(e.dataState)
            }}
          />
        </Grid>
      </Grid>
      <ModalDialog
        isOpen={openEditModal}
        header="Edit Portfolio Data Manager"
        buttonOkText="Save"
        disableOkButton={
          columnsRequest.length === 0 &&
          unmaksEntireFile ===
            fileUnmaskData?.sellerUploadTemplateFileUnmask.find(
              (x) => x.sellerUploadTemplateId === editTemplateId
            )?.fileUnmask?.allColumnsSelected
        }
        onClose={() => {
          setOpenEditModal(false)
          setColumnsRequest([])
        }}
        isFetching={addOrRemoveFileUnmaskLoading}
        isLoading={addOrRemoveFileUnmaskLoading}
        onContinue={handleSaveEditUnmask}
        width="80%"
      >
        <Box display="flex" flexDirection="column" gap={2}>
          {!templateFileMapLoading ? (
            <Box display="flex" width="100%" justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    onChange={() => setUnmaksEntireFile(!unmaksEntireFile)}
                    name="unmaskEntireFile"
                    checked={unmaksEntireFile}
                    value={unmaksEntireFile}
                  />
                }
                label="Unmask the entire file"
              />
            </Box>
          ) : (
            <Skeleton style={{ marginTop: '-2px' }} height={40} width={150} />
          )}
          <DataTable
            isLoading={templateFileMapLoading}
            isFetching={templateFileMapLoading}
            gridColumns={gridColumnsEdit}
            gridState={gridStateEdit}
            data={templateFileMap?.columns}
            total={fileUnmaskData?.total}
          />
        </Box>
      </ModalDialog>
    </Box>
  )
}

export default FileUnmaskList
