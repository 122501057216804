import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import { Close } from '@mui/icons-material'
import AuthService from 'src/configs/AuthService'
import { DialogTitleStyled } from './style'

interface SessionExpiringModalProps {
  children: React.ReactNode
}

const SessionExpiringModal: React.FC<SessionExpiringModalProps> = ({
  children,
}) => {
  const auth = useMemo(() => new AuthService(), [])
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [seconds, setSeconds] = useState(60)

  useEffect(() => {
    const countdown = setInterval(() => {
      if (openContactDialog) {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          clearInterval(countdown)
          auth.logout()
        }
      } else {
        clearInterval(countdown)
        setSeconds(60)
      }
    }, 1000)

    return () => {
      clearInterval(countdown)
    }
  }, [openContactDialog, seconds, auth])

  const handleOnExpiringAccessToken = (event: any) => {
    setOpenContactDialog(true)
  }

  auth.expiringAccessToken(handleOnExpiringAccessToken)

  return (
    <>
      {children}
      <Dialog
        open={openContactDialog}
        onClose={() => setOpenContactDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            Session Expiring
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenContactDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Typography component="div">
            You have been logged in for almost 24 hours. For security reasons,
            you will be automatically signed out in{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              {seconds}
            </Box>{' '}
            seconds. To continue your session, click{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              "Stay Signed In"
            </Box>{' '}
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              auth.renewToken()
              setOpenContactDialog(false)
            }}
            variant="contained"
            color="primary"
          >
            Stay Signed In
          </Button>
          <Button
            onClick={() => {
              auth.logout()
            }}
            variant="contained"
          >
            Sign me out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SessionExpiringModal
