import React from 'react'
import { IconButton, Typography, Dialog } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DialogTitleStyled } from './style'

interface DefaultDialogProps {
  title?: string
  children?: React.ReactNode
  onClose: () => void
  open: boolean
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined
}

const DefaultDialog: React.FC<DefaultDialogProps> = ({
  title,
  children,
  onClose,
  open,
  maxWidth,
}: DefaultDialogProps) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="default-dialog-title"
      maxWidth={maxWidth}
    >
      <DialogTitleStyled id="reject-dialog-title">
        <Typography
          variant="body1"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <IconButton
          data-cy="close-branch-modal-button"
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitleStyled>
      {children}
    </Dialog>
  )
}

DefaultDialog.defaultProps = {
  title: '',
  children: null,
  maxWidth: 'sm',
}

export default DefaultDialog
