import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
} from '@mui/material'
import { Save } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import {
  useAddBusinessLicense,
  useEditBusinessLicense,
} from 'src/graphql/operations/mutations/licenses'
import * as Yup from 'yup'
import hardCodeData from 'src/utils/hardcodeData'
import { getStateDropDownName } from 'src/utils/common'
import { CountryDataResponse } from 'src/graphql/models/clientProfiles'
import { useQuery } from '@apollo/client'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { getLocaleDateString } from 'src/utils/date'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { DefaultDialog } from '../Dialogs'
import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'

interface LicenseProps {
  businessId: string
  onClose: () => void
  open: boolean
  clientCountry?: string | null
  businessCountry?: string | null
  initialValue?: any | null
}

const licenseSchema = Yup.object().shape({
  businessId: Yup.string().required('Required'),
  stateCode: Yup.string().required('Required'),
  countryCode: Yup.string().required('Required'),
})

const License = ({
  businessId,
  onClose,
  open,
  clientCountry,
  businessCountry,
  initialValue,
}: LicenseProps) => {
  const { data: countryResponse } = useQuery<CountryDataResponse>(GET_COUNTRY, {
    onCompleted: (data: CountryDataResponse) => {
      if (!clientCountry && !businessCountry) {
        licenseForm.setFieldValue(
          'countryCode',
          data.countryDataResponse[0].countryCode
        )
      }
    },
  })

  const { profileClient } = useContext(AuthContext)

  const country =
    businessCountry ||
    clientCountry ||
    (countryResponse && countryResponse?.countryDataResponse[0].countryCode)

  const showCountryOption =
    !businessCountry &&
    !clientCountry &&
    countryResponse &&
    countryResponse?.countryDataResponse.length > 1

  const initialValuesData = {
    id: -1,
    businessId,
    comment: '',
    issuedDate: null,
    expirationDate: null,
    stateCode: '',
    countryCode: country,
    city: '',
  }

  const [initialValues, setInitialValues] = useState(initialValuesData)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (initialValue)
      setInitialValues({
        ...initialValue,
        issuedDate: new Date(initialValue.issuedDate),
        expirationDate: new Date(initialValue.expirationDate),
      })
    else setInitialValues(initialValuesData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  const handleAddLicenseCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('License added successfully', {
        variant: 'success',
      })
    }
  }
  const handleEditLicenseCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('License edited successfully', {
        variant: 'success',
      })
    }
  }
  const formatInput = getLocaleDateString()
  const formatPlaceHolder = getLocaleDateString(undefined, true)

  const { AddLicense, loading: licenseLoading } = useAddBusinessLicense(
    handleAddLicenseCompleted
  )
  const { EditLicense, loading: editLicenseLoading } = useEditBusinessLicense(
    handleEditLicenseCompleted
  )

  const validate = (values: any) => {
    const errors: any = {}

    if (
      values.issuedDate &&
      !moment(values.issuedDate, formatInput.toUpperCase(), true).isValid()
    ) {
      errors.issuedDate = 'Invalid date'
    }

    if (
      values.expirationDate &&
      !moment(values.expirationDate, formatInput.toUpperCase(), true).isValid()
    ) {
      errors.expirationDate = 'Invalid date'
    }

    if (
      values.issuedDate &&
      values.expirationDate &&
      values.expirationDate < values.issuedDate
    ) {
      errors.expirationDate = 'Expiration Date cannot be before Issued Date'
    }

    return errors
  }
  const licenseForm = useFormik({
    initialValues,
    validationSchema: licenseSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const licenseRequest = {
        comment: values.comment || null,
        issuedDate: values.issuedDate || null,
        expirationDate: values.expirationDate || null,
        stateCode: values.stateCode || null,
        countryCode: values.countryCode || null,
        businessId: values.businessId || null,
        city: values.city || null,
      }

      if (values?.id > 0) {
        EditLicense({
          variables: {
            request: {
              ...licenseRequest,
              id: values.id,
            },
          },
          refetchQueries: ['GetBusinessLicenses'],
        })
      } else {
        AddLicense({
          variables: {
            request: {
              ...licenseRequest,
            },
          },
          refetchQueries: ['GetBusinessLicenses'],
        })
      }
      setSubmitting(false)
      resetForm()
      onClose()
    },
  })

  const closeModal = () => {
    licenseForm.resetForm()
    onClose()
  }

  return (
    <DefaultDialog open={open} title="Add License" onClose={closeModal}>
      <form onSubmit={licenseForm.handleSubmit}>
        <DialogContent>
          {showCountryOption && (
            <Box mb={4}>
              <TextField
                fullWidth
                label="Country"
                name="countryCode"
                select
                onChange={licenseForm.handleChange}
                error={!!licenseForm.errors.countryCode}
                value={licenseForm.values.countryCode}
                helperText={licenseForm.errors.countryCode}
              >
                {hardCodeData.getCountries().map((option) => (
                  <MenuItem key={option.codeCountry} value={option.codeCountry}>
                    {option.description}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
          <Box mb={4}>
            <TextField
              fullWidth
              label={getStateDropDownName(country)}
              name="stateCode"
              select
              onChange={licenseForm.handleChange}
              error={!!licenseForm.errors.stateCode}
              value={licenseForm.values.stateCode}
              helperText={licenseForm.errors.stateCode}
            >
              {hardCodeData
                .getStates(licenseForm.values.countryCode)
                .map((option) => (
                  <MenuItem key={option.codeKey} value={option.codeKey}>
                    {option.description}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box mb={4}>
            <TextField
              fullWidth
              label="City"
              name="city"
              maxRows={4}
              value={licenseForm.values.city}
              onChange={licenseForm.handleChange}
              error={!!licenseForm.errors.city}
              helperText={licenseForm.errors.city}
            />
          </Box>
          <Box mb={4}>
            <DatePicker
              name="issuedDate"
              label="Issued Date"
              value={licenseForm.values.issuedDate}
              errorMessage={licenseForm.errors.issuedDate}
              onChange={(date) => {
                licenseForm.setFieldValue('issuedDate', date, true)
              }}
              country={profileClient?.Country}
            />
          </Box>
          <Box mb={4}>
            <DatePicker
              name="expirationDate"
              label="Expiration Date"
              minDate={licenseForm.values.issuedDate ?? undefined}
              errorMessage={licenseForm.errors.expirationDate}
              value={licenseForm.values.expirationDate}
              onChange={(date) => {
                licenseForm.setFieldValue('expirationDate', date, true)
              }}
              country={profileClient?.Country}
            />
          </Box>
          <Box mb={4}>
            <TextField
              fullWidth
              label="Comment"
              name="comment"
              maxRows={4}
              value={licenseForm.values.comment}
              onChange={licenseForm.handleChange}
              error={!!licenseForm.errors.comment}
              helperText={licenseForm.errors.comment}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!licenseForm.isValid}
            startIcon={
              licenseLoading || editLicenseLoading ? (
                <CircularProgress size={16} />
              ) : (
                <Save />
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </DefaultDialog>
  )
}
License.defaultProps = {
  clientCountry: null,
  businessCountry: null,
  initialValue: null,
}

export default License
