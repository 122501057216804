/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { userGoal } from 'src/graphql/models/commissions'
import { useSnackbar } from 'notistack'
import { useAddOrUpdateUserGoal } from 'src/graphql/operations/mutations/commissions'
import { useFormik } from 'formik'
import { DefaultDialog } from 'src/components/Dialogs'
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import { maskMoney, unMaskMoney } from 'src/utils/masker'
import { Save } from '@mui/icons-material'
import { formatDate } from 'src/utils/date'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DatePicker } from 'everchain-uilibrary'

interface DefaultValuesProps {
  id: number | string | undefined
  amount: number | any
  startDate: Date | null
  userId: string
}

const initialValuesDefault = {
  id: '',
  amount: null,
  startDate: null,
  userId: '',
}

interface UserGoalParam {
  userId: string
  userGoal: userGoal | undefined
  open?: boolean
  onClose: () => void
}

const formSchema = Yup.object().shape({
  amount: Yup.string().nullable().required('Required'),
  startDate: Yup.date().nullable().required('Required'),
})

const UserGoal: React.FC<UserGoalParam> = ({
  userId,
  userGoal,
  open = false,
  onClose,
}: UserGoalParam) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { AddOrUpdateUserGoal, loading: formLoading } = useAddOrUpdateUserGoal({
    onCompleted: () => {
      enqueueSnackbar('User Goal saved successfully', notifySuccess)
      onClose()
    },
    onError: () => {
      enqueueSnackbar('You can not select a date in the past.', notifyError)
      onClose()
    },
  })
  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )

  const userGoalForm = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id: values.id || null,
        userId,
        amount: unMaskMoney(values.amount),
        startDate: values.startDate
          ? new Date(formatDate(values.startDate))
          : null,
      }

      if (userId || userGoal) {
        AddOrUpdateUserGoal({
          variables: {
            userGoalRequest: {
              ...requestValues,
            },
          },
          refetchQueries: ['GetUserGoals'],
        })
      }

      setSubmitting(false)
    },
  })
  useEffect(() => {
    if (userGoal && userGoal.id) {
      setInitialValues((prevState) => ({
        ...prevState,
        id: userGoal.id || '',
        amount: userGoal.amount || '',
        startDate: userGoal.startDate || '',
        userId: userId || '',
      }))
    }
  }, [userGoal, userId])

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={`${userGoal?.id ? 'Edit' : 'Add'} User Goal`}
    >
      <form onSubmit={userGoalForm.handleSubmit}>
        <DialogContent>
          <Box mb={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                  name="startDate"
                  id="startDate"
                  label="Period"
                  views={['year', 'month']}
                  value={userGoalForm.values.startDate}
                  errorMessage={userGoalForm.errors.startDate}
                  onChange={(date) => {
                    if (date && date < actualDate) {
                      enqueueSnackbar(
                        'You can not select a date in the past.',
                        notifyError
                      )
                    } else {
                      userGoalForm.setFieldValue('startDate', date, false)
                    }
                  }}
                  inputFormat="YYYY/MM"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={({ target: { value, name } }) => {
                    const masked = maskMoney(value)
                    userGoalForm.setFieldValue(name, masked, false)
                  }}
                  value={userGoalForm.values.amount}
                  error={!!userGoalForm.errors.amount}
                  helperText={<>{userGoalForm.errors.amount}</>}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            type="submit"
            startIcon={formLoading ? <CircularProgress size={16} /> : <Save />}
            variant="contained"
            disabled={formLoading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </DefaultDialog>
  )
}

export default UserGoal
