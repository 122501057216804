import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton,
  DialogContent,
  DialogActions,
  Skeleton,
} from '@mui/material'
import { Save, List as ListIcon } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import {
  CountryDataResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import { useAddOrUpdateClient } from 'src/graphql/operations/mutations/clientProfiles'
import hardCodeData from 'src/utils/hardcodeData'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'
import {
  maskPhoneNumber,
  maskZipCode,
  unmaskPhoneNumber,
} from 'src/utils/masker'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { useQuery } from '@apollo/client'
import { getStateDropDownName } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE, PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { DefaultDialog } from 'src/components/Dialogs'
import { ClientNameHistory } from 'src/components/NameHistory'
import { BusinessStatusInfo } from 'src/components/BusinessStatusInfo'

interface ClientProfileFormProps {
  clientProfileId?: number | undefined
  clientData?: GetClient | undefined
}

const ClientProfileForm = ({
  clientProfileId,
  clientData,
}: ClientProfileFormProps) => {
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const handleAddOrUpdateclientCompleted = (data: any) => {
    if (data) {
      let text = 'Create'
      if (clientProfileId && clientData) {
        text = 'Update'
      }
      enqueueSnackbar(`${text} client successful`, {
        variant: 'success',
      })
      if (data.addOrUpdateClient) {
        history.push(`${CLIENT_PROFILES_MANAGE}/${data.addOrUpdateClient.id}`)
      }
    }
  }

  const { addOrUpdateClient, loading } = useAddOrUpdateClient(
    handleAddOrUpdateclientCompleted
  )

  const { data: countryResponse, loading: loadingCountryData } =
    useQuery<CountryDataResponse>(GET_COUNTRY)
  const countryCode = clientData?.countryCode || process.env.REACT_APP_COUNTRY

  const [initialValues, setInitialValues] = useState<any>({
    name: '',
    category: '',
    city: '',
    status: 'Application In Process',
    countryCode: '',
    stateCode: '',
    postalCode: '',
    street1: '',
    street2: '',
    corpHQPhoneNumber: '',
    customerServicePhoneNumber: '',
    customerServiceEmail: '',
    primaryContact_LastName: '',
    primaryContact_FirstName: '',
    primaryContact_EMail: '',
    primaryContact_OfficePhone: '',
    primaryContact_MobilePhone: '',
    digitalSellerSurveySignerTitle: '',
    digitalSellerSurveySignerName: '',
    enableDigitalSellerSurvey: false,
    eSignatureEnabledBOS: false,
    eSignatureEnabledPSA: false,
  })

  const clinetProfileSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    stateCode: Yup.string().nullable().required('Required'),
    countryCode: Yup.string().nullable().required('Required'),
    category: Yup.string().nullable().required('Required'),
    status: Yup.string().nullable().required('Required'),
    postalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        if (clientForm.values.countryCode?.toLowerCase() === 'us') {
          const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
          return regex.test(value || '')
        }
        if (clientForm.values.countryCode?.toLowerCase() === 'uk') {
          const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
          return regex.test(value || '')
        }
        if (clientForm.values.countryCode?.toLowerCase() === 'ca') {
          const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
          return regex.test(value || '')
        }
        return false
      }
    ),
    street1: Yup.string().nullable(),
    street2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    corpHQPhoneNumber: Yup.string().nullable(),
    customerServicePhoneNumber: Yup.string().nullable(),
    customerServiceEmail: Yup.string().email('Email is invalid').nullable(),
    primaryContact_LastName: Yup.string().nullable(),
    primaryContact_FirstName: Yup.string().nullable(),
    primaryContact_EMail: Yup.string().email('Email is invalid').nullable(),
    primaryContact_OfficePhone: Yup.string().nullable(),
    primaryContact_MobilePhone: Yup.string().nullable(),
  })

  const clientForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: clinetProfileSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        digitalSellerSurveySignerTitle:
          values.digitalSellerSurveySignerTitle || null,
        digitalSellerSurveySignerName:
          values.digitalSellerSurveySignerName || null,
        enableDigitalSellerSurvey: values.enableDigitalSellerSurvey,
        eSignatureEnabledBOS: values.eSignatureEnabledBOS,
        eSignatureEnabledPSA: values.eSignatureEnabledPSA,
        name: values.name || null,
        category: values.category || null,
        city: values.city || null,
        status: values.status || null,
        countryCode: values.countryCode || null,
        stateCode: values.stateCode || null,
        postalCode: values.postalCode || null,
        street1: values.street1 || null,
        street2: values.street2 || null,
        customerServiceEmail: values.customerServiceEmail || null,
        primaryContact_LastName: values.primaryContact_LastName || null,
        primaryContact_FirstName: values.primaryContact_FirstName || null,
        primaryContact_EMail: values.primaryContact_EMail || null,
        corpHQPhoneNumber: values.corpHQPhoneNumber
          ? unmaskPhoneNumber(values.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: values.customerServicePhoneNumber
          ? unmaskPhoneNumber(values.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: values.primaryContact_OfficePhone
          ? unmaskPhoneNumber(values.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: values.primaryContact_MobilePhone
          ? unmaskPhoneNumber(values.primaryContact_MobilePhone, countryCode)
          : null,
      }
      if (clientProfileId) {
        addOrUpdateClient({
          variables: {
            client: {
              id: Number(clientProfileId),
              ...requestValues,
            },
          },
          refetchQueries: ['GetClientNameHistory'],
        })
      } else {
        addOrUpdateClient({
          variables: {
            client: {
              ...requestValues,
            },
          },
        })
      }
      setSubmitting(false)
    },
  })
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const showCountryOption =
    countryResponse && countryResponse?.countryDataResponse.length > 1
  const [openNameHistory, setOpenNameHistory] = useState(false)
  const ability = useContext(AbilityContext)
  const canEditStatus = ability.can(
    PermissionCodeAccess.CLIENT_STATUS_PERMISSION,
    'any'
  )
  const canEditField = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  useEffect(() => {
    if (clientData) {
      setInitialValues({
        digitalSellerSurveySignerTitle:
          clientData.digitalSellerSurveySignerTitle || null,
        digitalSellerSurveySignerName:
          clientData.digitalSellerSurveySignerName || null,
        enableDigitalSellerSurvey: clientData.enableDigitalSellerSurvey,
        eSignatureEnabledBOS: clientData.eSignatureEnabledBOS || false,
        eSignatureEnabledPSA: clientData.eSignatureEnabledPSA || false,
        isReseller: clientData.isReseller,
        name: clientData.name || '',
        category: clientData.category || '',
        city: clientData.city || '',
        status: clientData.status || '',
        countryCode: clientData.countryCode || '',
        stateCode: clientData.stateCode || '',
        postalCode: clientData.postalCode || '',
        street1: clientData.street1 || '',
        street2: clientData.street2 || '',
        customerServiceEmail: clientData.customerServiceEmail || '',
        primaryContact_LastName: clientData.primaryContact_LastName || '',
        primaryContact_FirstName: clientData.primaryContact_FirstName || '',
        primaryContact_EMail: clientData.primaryContact_EMail || '',
        corpHQPhoneNumber: clientData.corpHQPhoneNumber
          ? maskPhoneNumber(clientData.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: clientData.customerServicePhoneNumber
          ? maskPhoneNumber(clientData.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: clientData.primaryContact_OfficePhone
          ? maskPhoneNumber(clientData.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: clientData.primaryContact_MobilePhone
          ? maskPhoneNumber(clientData.primaryContact_MobilePhone, countryCode)
          : null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData])

  const handleNameHistoryToogle = () => {
    setOpenNameHistory(!openNameHistory)
  }

  if (loadingCountryData) {
    return <Box />
  }

  return (
    <Box>
      <form onSubmit={clientForm.handleSubmit}>
        {isInternal && (
          <Box mb={12}>
            <Typography variant="h6">Client Information</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={clientForm.handleChange}
                  error={!!clientForm.errors.name}
                  value={clientForm.values.name}
                  helperText={<>{clientForm.errors.name}</>}
                  disabled={!isInternal || !canEditField}
                  InputProps={{
                    endAdornment: clientProfileId && (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={handleNameHistoryToogle}
                        >
                          <ListIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Category"
                  name="category"
                  select
                  disabled={!isInternal || !canEditField}
                  onChange={({ target: { value, name } }) => {
                    clientForm.setFieldValue(name, value, false)
                    if (
                      !hardCodeData
                        .getBusinessStatus(value)
                        .find((e) => e.value === clientForm.values.status)
                    )
                      clientForm.setFieldValue('status', null, false)
                  }}
                  error={!!clientForm.errors.category}
                  value={clientForm.values.category}
                  helperText={<>{clientForm.errors.category}</>}
                >
                  {hardCodeData.getCategories().map((option) => (
                    <MenuItem
                      key={option.codeCategory}
                      value={option.codeCategory}
                    >
                      {option.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} lg={4} display="flex" flexDirection="row">
                <TextField
                  fullWidth
                  label="Status"
                  select
                  name="status"
                  disabled={!isInternal || !canEditStatus}
                  onChange={clientForm.handleChange}
                  error={!!clientForm.errors.status}
                  value={clientForm.values.status}
                  helperText={<>{clientForm.errors.status}</>}
                >
                  {hardCodeData
                    .getBusinessStatus(clientForm?.values.category)
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
                <BusinessStatusInfo
                  businessType={clientForm?.values.category}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mb={12}>
          <Typography variant="h6">Address</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street"
                name="street1"
                onChange={clientForm.handleChange}
                error={!!clientForm.errors.street1}
                value={clientForm.values.street1}
                helperText={<>{clientForm.errors.street1}</>}
                disabled={!isInternal || !canEditField}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street 2"
                name="street2"
                onChange={clientForm.handleChange}
                error={!!clientForm.errors.street2}
                value={clientForm.values.street2}
                helperText={<>{clientForm.errors.street2}</>}
                disabled={!isInternal || !canEditField}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={clientForm.handleChange}
                error={!!clientForm.errors.city}
                value={clientForm.values.city}
                helperText={<>{clientForm.errors.city}</>}
                disabled={!isInternal || !canEditField}
              />
            </Grid>
            {showCountryOption && (
              <Grid item xs={12} lg={4}>
                {loadingCountryData ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <TextField
                    fullWidth
                    label="Country"
                    name="countryCode"
                    select
                    onChange={({ target: { value, name } }) => {
                      clientForm.setFieldValue(name, value, true)
                      const masked = maskZipCode(
                        '',
                        clientForm.values.countryCode
                      )
                      clientForm.setFieldValue('postalCode', masked, true)
                    }}
                    error={!!clientForm.errors.countryCode}
                    value={clientForm.values.countryCode}
                    helperText={<>{clientForm.errors.countryCode}</>}
                    disabled={!isInternal || !canEditField}
                  >
                    {countryResponse?.countryDataResponse.map((option) => (
                      <MenuItem
                        key={option.countryCode}
                        value={option.countryCode}
                      >
                        {option.countryDescription}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
            )}
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label={getStateDropDownName(countryCode)}
                  name="stateCode"
                  select
                  onChange={clientForm.handleChange}
                  error={!!clientForm.errors.stateCode}
                  value={clientForm.values.stateCode}
                  helperText={<>{clientForm.errors.stateCode}</>}
                  disabled={!isInternal || !canEditField}
                >
                  {countryResponse?.countryDataResponse
                    .find(
                      (x) => x.countryCode === clientForm.values.countryCode
                    )
                    ?.stateInfo.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.description}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Postal Code"
                name="postalCode"
                onChange={({ target: { value, name } }) => {
                  const masked = maskZipCode(
                    value,
                    clientForm.values.countryCode
                  )
                  clientForm.setFieldValue(name, masked, true)
                }}
                disabled={!isInternal || !canEditField}
                error={!!clientForm.errors.postalCode}
                value={clientForm.values.postalCode}
                helperText={<>{clientForm.errors.postalCode}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Contact Information</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="HQ Phone"
                name="corpHQPhoneNumber"
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  clientForm.setFieldValue(name, masked, false)
                }}
                disabled={!isInternal || !canEditField}
                error={!!clientForm.errors.corpHQPhoneNumber}
                value={clientForm.values.corpHQPhoneNumber}
                helperText={<>{clientForm.errors.corpHQPhoneNumber}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Customer Services Phone"
                name="customerServicePhoneNumber"
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  clientForm.setFieldValue(name, masked, false)
                }}
                disabled={!isInternal || !canEditField}
                error={!!clientForm.errors.customerServicePhoneNumber}
                value={clientForm.values.customerServicePhoneNumber}
                helperText={<>{clientForm.errors.customerServicePhoneNumber}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Customer Services E-mail"
                name="customerServiceEmail"
                disabled={!isInternal || !canEditField}
                onChange={clientForm.handleChange}
                error={!!clientForm.errors.customerServiceEmail}
                value={clientForm.values.customerServiceEmail}
                helperText={<>{clientForm.errors.customerServiceEmail}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Admin Client</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Last Name"
                name="primaryContact_LastName"
                onChange={clientForm.handleChange}
                disabled={!isInternal || !canEditField}
                error={!!clientForm.errors.primaryContact_LastName}
                value={clientForm.values.primaryContact_LastName}
                helperText={<>{clientForm.errors.primaryContact_LastName}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="First Name"
                name="primaryContact_FirstName"
                onChange={clientForm.handleChange}
                disabled={!isInternal || !canEditField}
                error={!!clientForm.errors.primaryContact_FirstName}
                value={clientForm.values.primaryContact_FirstName}
                helperText={<>{clientForm.errors.primaryContact_FirstName}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="E-mail"
                name="primaryContact_EMail"
                onChange={clientForm.handleChange}
                disabled={!isInternal || !canEditField}
                error={!!clientForm.errors.primaryContact_EMail}
                value={clientForm.values.primaryContact_EMail}
                helperText={<>{clientForm.errors.primaryContact_EMail}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Direct (Phone)"
                name="primaryContact_OfficePhone"
                disabled={!isInternal || !canEditField}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  clientForm.setFieldValue(name, masked, false)
                }}
                error={!!clientForm.errors.primaryContact_OfficePhone}
                value={clientForm.values.primaryContact_OfficePhone}
                helperText={<>{clientForm.errors.primaryContact_OfficePhone}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Mobile"
                name="primaryContact_MobilePhone"
                disabled={!isInternal || !canEditField}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  clientForm.setFieldValue(name, masked, false)
                }}
                error={!!clientForm.errors.primaryContact_MobilePhone}
                value={clientForm.values.primaryContact_MobilePhone}
                helperText={<>{clientForm.errors.primaryContact_MobilePhone}</>}
              />
            </Grid>
          </Grid>
        </Box>
        {isInternal &&
          (clientForm.values.category === 'Seller' ||
            clientForm.values.isReseller) && (
            <Box mb={12}>
              <Typography variant="h6">Digital Seller Survey</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => {
                          clientForm.handleChange(e)
                          if (!e.target.checked) {
                            clientForm.setFieldValue(
                              'digitalSellerSurveySignerName',
                              '',
                              false
                            )
                            clientForm.setFieldValue(
                              'digitalSellerSurveySignerTitle',
                              '',
                              false
                            )
                          }
                        }}
                        name="enableDigitalSellerSurvey"
                        disabled={!isInternal || !canEditField}
                        checked={clientForm.values.enableDigitalSellerSurvey}
                        value={clientForm.values.enableDigitalSellerSurvey}
                      />
                    }
                    label="Enable Digital Seller Survey"
                  />
                </Grid>
                {clientForm.values.enableDigitalSellerSurvey && (
                  <>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        fullWidth
                        label="Digital Seller Survey Signer Name"
                        name="digitalSellerSurveySignerName"
                        disabled={!isInternal || !canEditField}
                        onChange={clientForm.handleChange}
                        value={clientForm.values.digitalSellerSurveySignerName}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        fullWidth
                        label="Digital Seller Survey Signer Title"
                        name="digitalSellerSurveySignerTitle"
                        disabled={!isInternal || !canEditField}
                        onChange={clientForm.handleChange}
                        value={clientForm.values.digitalSellerSurveySignerTitle}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        {isInternal && (
          <Box mb={12}>
            {clientForm.values.category !== 'Servicer' &&
              clientForm.values.category !== '' && (
                <Typography variant="h6">E-Signature</Typography>
              )}
            <Grid container spacing={4}>
              {(clientForm.values.category === 'Seller' ||
                clientForm.values.isReseller) && (
                <Grid item xs={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => {
                          clientForm.handleChange(e)
                        }}
                        name="eSignatureEnabledBOS"
                        disabled={!isInternal || !canEditField}
                        checked={clientForm.values.eSignatureEnabledBOS}
                        value={clientForm.values.eSignatureEnabledBOS}
                      />
                    }
                    label="Enable BOS E-signature"
                  />
                </Grid>
              )}
              {clientForm.values.category !== 'Servicer' && (
                <Grid item xs={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => {
                          clientForm.handleChange(e)
                        }}
                        name="eSignatureEnabledPSA"
                        disabled={!isInternal || !canEditField}
                        checked={clientForm.values.eSignatureEnabledPSA}
                        value={clientForm.values.eSignatureEnabledPSA}
                      />
                    }
                    label="Enable PSA E-signature"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {(canEditStatus || (isInternal && canEditField)) && (
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button
              startIcon={!loading ? <Save /> : <CircularProgress size={16} />}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </Box>
        )}
        <DefaultDialog
          title="Name History"
          open={openNameHistory}
          onClose={handleNameHistoryToogle}
        >
          <DialogContent>
            {clientProfileId && (
              <ClientNameHistory
                clientId={clientProfileId}
                refetchQueries={['GetClient']}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleNameHistoryToogle}
            >
              Close
            </Button>
          </DialogActions>
        </DefaultDialog>
      </form>
    </Box>
  )
}

ClientProfileForm.defaultProps = {
  clientProfileId: null,
  clientData: null,
}
export default ClientProfileForm
