import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {
  CssBaseline,
  unstable_createMuiStrictModeTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
  IconButton,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { SnackbarProvider, MaterialDesignContent, useSnackbar } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { HelmetProvider } from 'react-helmet-async'
import { GlobalStyle, defaultTheme } from 'src/styles'
import { client } from 'src/configs/apolloConfig'
import { AuthProvider } from 'src/context/AuthenticationContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Colors } from 'everchain-uilibrary'

import App from './app'
import IdleTimeout from './components/IdleTimeout'
import SessionExpiringModal from './components/SessionTimeout'

const createMuiTheme = unstable_createMuiStrictModeTheme

const theme = createMuiTheme(defaultTheme)
const themeResponsiveFonts = responsiveFontSizes(theme)

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: Colors.blue,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: Colors.error,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: Colors.warning,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: Colors.info,
  },
}))

const DismissAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton key="close" color="inherit" onClick={() => closeSnackbar(id)}>
      <Close />
    </IconButton>
  )
}

const Root: React.FC = () => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={themeResponsiveFonts}>
        <ThemeProvider theme={themeResponsiveFonts}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ApolloProvider client={client}>
              <CssBaseline />
              <GlobalStyle />
              <HelmetProvider>
                <Router>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    hideIconVariant={true}
                    action={(key) => <DismissAction id={key} />}
                    Components={{
                      success: StyledMaterialDesignContent,
                      error: StyledMaterialDesignContent,
                      warning: StyledMaterialDesignContent,
                      info: StyledMaterialDesignContent,
                    }}
                  >
                    <AuthProvider>
                      <SessionExpiringModal>
                        <IdleTimeout>
                          <App />
                        </IdleTimeout>
                      </SessionExpiringModal>
                    </AuthProvider>
                  </SnackbarProvider>
                </Router>
              </HelmetProvider>
            </ApolloProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}

export default Root
