/* eslint-disable react/require-default-props */
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  Button,
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
} from '@mui/material'
import React from 'react'
import { Close } from '@mui/icons-material'
import { DialogTitleStyled } from 'src/components/Dialogs/style'

interface LogDetailProps {
  data?: any
  title: string
  isOpen: boolean
  onClose: () => void
}

const LogDetail: React.FC<LogDetailProps> = ({
  data = [],
  title,
  isOpen,
  onClose,
}: LogDetailProps) => {
  return (
    <Dialog open={isOpen} title={title}>
      <DialogTitleStyled id="reject-dialog-title">
        <Typography
          variant="body1"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <IconButton
          data-cy="close-branch-modal-button"
          aria-label="close"
          onClick={() => onClose()}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitleStyled>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {data &&
              data.map((row: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box m={2} justifyContent="flex-end" display="flex">
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Dialog>
  )
}

export default LogDetail
