/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  Grid,
  MenuItem,
  Divider,
  CircularProgress,
  Skeleton,
} from '@mui/material'
import { Save } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { DefaultDialog } from 'src/components/Dialogs'
import hardcodeData from 'src/utils/hardcodeData'
import { useAddOrUpdateBankAccount } from 'src/graphql/operations/mutations/clientProfiles'
import {
  ClientBankAccountResponse,
  CountryDataResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import {
  maskZipCode,
  maskPhoneNumber,
  unmaskPhoneNumber,
} from 'src/utils/masker'
import { useQuery } from '@apollo/client'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { getStateDropDownName } from 'src/utils/common'

interface ClientBankAccountFormProps {
  open?: boolean
  clientProfileId?: number | undefined
  onClose: () => void
  bankAccountData?: ClientBankAccountResponse | undefined
  client?: GetClient
}

const ClientBankAccountForm = ({
  open = false,
  clientProfileId,
  onClose,
  bankAccountData,
  client,
}: ClientBankAccountFormProps) => {
  const { data: countryResponse, loading: loadingCountryData } =
    useQuery<CountryDataResponse>(GET_COUNTRY, {})

  const country = client?.countryCode || process.env.REACT_APP_COUNTRY

  const showCountryOption =
    countryResponse && countryResponse?.countryDataResponse.length > 1

  const [initialValues, setInitialValues] = useState({
    bankName: '',
    street1: '',
    street2: '',
    city: '',
    stateCode: '',
    postalCode: '',
    countryCode: country,
    bankPhone: '',
    routingNumber: '',
    nameOnAccount: '',
    accountNumber: '',
    accountTypeCode: '',
    status: '',
  })

  const { enqueueSnackbar } = useSnackbar()

  const handleAddOrUpdateBankAccountCompleted = (data: any) => {
    if (data) {
      let text = 'Create'
      if (clientProfileId && bankAccountData?.id) {
        text = 'Update'
      }
      enqueueSnackbar(`${text} bank account successful`, {
        variant: 'success',
      })
      onClose()
    }
  }
  const { addOrUpdateBankAccount, loading: bankAccountLoading } =
    useAddOrUpdateBankAccount({
      onCompleted: (data: any) => {
        handleAddOrUpdateBankAccountCompleted(data)
      },
      onError: (error: any) => {
        handleOnError(error)
      },
    })
  const handleOnError = (error: any) => {
    if (error) {
      enqueueSnackbar(
        `${error.message.replace('GraphQL.ExecutionError: ', '')} `,
        {
          variant: 'error',
        }
      )
    }
  }

  const bankAccountFormSchema = Yup.object().shape({
    bankName: Yup.string().required('Required'),
    bankPhone: Yup.string().required('Required'),
    accountTypeCode: Yup.string().when({
      is: () => country === 'UK',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('Required'),
    }),
    status: Yup.string().required('Required'),
    accountNumber: Yup.string().required('Required'),
    routingNumber: Yup.string().required('Required'),
    postalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        if (bankAccountForm.values.countryCode?.toLowerCase() === 'us') {
          const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
          return regex.test(value || '')
        }
        if (bankAccountForm.values.countryCode?.toLowerCase() === 'uk') {
          const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
          return regex.test(value || '')
        }
        if (bankAccountForm.values.countryCode?.toLowerCase() === 'ca') {
          const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
          return regex.test(value || '')
        }
        return false
      }
    ),
  })

  const bankAccountForm = useFormik({
    initialValues,
    validationSchema: bankAccountFormSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        bankName: values.bankName || null,
        street1: values.street1 || null,
        street2: values.street2 || null,
        city: values.city || null,
        stateCode: values.stateCode || null,
        postalCode: values.postalCode || null,
        countryCode: values.countryCode || '',
        bankPhone: values.bankPhone
          ? unmaskPhoneNumber(values.bankPhone, country)
          : null,
        routingNumber: values.routingNumber || null,
        nameOnAccount: values.nameOnAccount || null,
        accountNumber: values.accountNumber || null,
        accountTypeCode: values.accountTypeCode || null,
        status: values.status || null,
      }

      if (clientProfileId && bankAccountData) {
        addOrUpdateBankAccount({
          variables: {
            request: {
              id: Number(bankAccountData.id),
              clientId: Number(clientProfileId),
              ...requestValues,
            },
          },
          refetchQueries: ['GetBankAccounts'],
        })
      } else if (clientProfileId) {
        addOrUpdateBankAccount({
          variables: {
            request: {
              clientId: Number(clientProfileId),
              ...requestValues,
            },
          },
          refetchQueries: ['GetBankAccounts'],
        })
      }
      setSubmitting(false)
    },
  })

  useEffect(() => {
    if (bankAccountData && bankAccountData.id) {
      setInitialValues((prevState) => ({
        ...prevState,
        bankName: bankAccountData.bankName || '',
        street1: bankAccountData.street1 || '',
        street2: bankAccountData.street2 || '',
        city: bankAccountData.city || '',
        stateCode: bankAccountData.stateCode || '',
        postalCode: bankAccountData.postalCode || '',
        countryCode: bankAccountData.countryCode || '',
        bankPhone: bankAccountData.bankPhone
          ? maskPhoneNumber(bankAccountData.bankPhone, country)
          : '',
        routingNumber: bankAccountData.routingNumber || '',
        nameOnAccount: bankAccountData.nameOnAccount || '',
        accountNumber: bankAccountData.accountNumber || '',
        accountTypeCode: bankAccountData.accountTypeCode || '',
        status: bankAccountData.status || '',
      }))
    }
  }, [bankAccountData, country])

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={`${bankAccountData?.id ? 'Edit' : 'Add'} Bank Account`}
    >
      <form onSubmit={bankAccountForm.handleSubmit}>
        <DialogContent>
          <Box mb={4}>
            <Typography variant="h6">Banking Info</Typography>
            <Divider />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Banking Name"
                  name="bankName"
                  onChange={bankAccountForm.handleChange}
                  value={bankAccountForm.values.bankName}
                  error={!!bankAccountForm.errors.bankName}
                  helperText={bankAccountForm.errors.bankName}
                />
              </Grid>
              {country !== 'UK' && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Account Type"
                    name="accountTypeCode"
                    select
                    onChange={bankAccountForm.handleChange}
                    value={bankAccountForm.values.accountTypeCode}
                    error={!!bankAccountForm.errors.accountTypeCode}
                    helperText={bankAccountForm.errors.accountTypeCode}
                  >
                    {hardcodeData.getAccountType().map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Account No."
                  name="accountNumber"
                  onChange={({ target: { value, name } }) => {
                    bankAccountForm.setFieldValue(name, value)
                  }}
                  value={bankAccountForm.values.accountNumber}
                  error={!!bankAccountForm.errors.accountNumber}
                  helperText={bankAccountForm.errors.accountNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label={country === 'UK' ? 'Sort Code' : 'Routing No.'}
                  name="routingNumber"
                  onChange={({ target: { value, name } }) => {
                    bankAccountForm.setFieldValue(name, value)
                  }}
                  inputProps={{ maxLength: country === 'UK' ? 8 : 9 }}
                  value={bankAccountForm.values.routingNumber}
                  error={!!bankAccountForm.errors.routingNumber}
                  helperText={bankAccountForm.errors.routingNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Name On Account"
                  name="nameOnAccount"
                  onChange={bankAccountForm.handleChange}
                  value={bankAccountForm.values.nameOnAccount}
                  error={!!bankAccountForm.errors.nameOnAccount}
                  helperText={bankAccountForm.errors.nameOnAccount}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Typography variant="h6">Bank Address</Typography>
            <Divider />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Street"
                  name="street1"
                  onChange={bankAccountForm.handleChange}
                  value={bankAccountForm.values.street1}
                  error={!!bankAccountForm.errors.street1}
                  helperText={bankAccountForm.errors.street1}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Street 2"
                  name="street2"
                  onChange={bankAccountForm.handleChange}
                  value={bankAccountForm.values.street2}
                  error={!!bankAccountForm.errors.street2}
                  helperText={bankAccountForm.errors.street2}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="City"
                  name="city"
                  onChange={bankAccountForm.handleChange}
                  value={bankAccountForm.values.city}
                  error={!!bankAccountForm.errors.city}
                  helperText={bankAccountForm.errors.city}
                />
              </Grid>
              {showCountryOption && (
                <Grid item xs={12} sm={6} md={4}>
                  {loadingCountryData ? (
                    <Skeleton variant="rectangular" width="100%" />
                  ) : (
                    <TextField
                      fullWidth
                      label="Country"
                      name="countryCode"
                      select
                      onChange={({ target: { value, name } }) => {
                        bankAccountForm.setFieldValue(name, value, true)
                        const masked = maskZipCode(
                          '',
                          bankAccountForm.values.countryCode
                        )
                        bankAccountForm.setFieldValue(
                          'postalCode',
                          masked,
                          true
                        )
                      }}
                      value={bankAccountForm.values.countryCode}
                      error={!!bankAccountForm.errors.countryCode}
                      helperText={bankAccountForm.errors.countryCode}
                    >
                      {countryResponse?.countryDataResponse.map((option) => (
                        <MenuItem
                          key={option.countryCode}
                          value={option.countryCode}
                        >
                          {option.countryDescription}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
                {loadingCountryData ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <TextField
                    fullWidth
                    label={getStateDropDownName(country)}
                    name="stateCode"
                    select
                    onChange={bankAccountForm.handleChange}
                    value={bankAccountForm.values.stateCode}
                    error={!!bankAccountForm.errors.stateCode}
                    helperText={bankAccountForm.errors.stateCode}
                  >
                    {countryResponse?.countryDataResponse
                      .find(
                        (x) =>
                          x.countryCode === bankAccountForm.values.countryCode
                      )
                      ?.stateInfo.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.description}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Bank Phone"
                  name="bankPhone"
                  onChange={({ target: { value, name } }) => {
                    const masked = maskPhoneNumber(value, country)
                    bankAccountForm.setFieldValue(name, masked)
                  }}
                  value={bankAccountForm.values.bankPhone}
                  error={!!bankAccountForm.errors.bankPhone}
                  helperText={bankAccountForm.errors.bankPhone}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Postal Code"
                  name="postalCode"
                  onChange={({ target: { value, name } }) => {
                    const masked = maskZipCode(
                      value,
                      bankAccountForm.values.countryCode
                    )
                    bankAccountForm.setFieldValue(name, masked)
                  }}
                  value={bankAccountForm.values.postalCode}
                  error={!!bankAccountForm.errors.postalCode}
                  helperText={bankAccountForm.errors.postalCode}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Typography variant="h6">EC Options</Typography>
            <Divider />
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Status"
                  name="status"
                  select
                  onChange={bankAccountForm.handleChange}
                  value={bankAccountForm.values.status}
                  error={!!bankAccountForm.errors.status}
                  helperText={bankAccountForm.errors.status}
                >
                  {hardcodeData.getDefaultStatus().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            // variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            startIcon={
              bankAccountLoading ? <CircularProgress size={16} /> : <Save />
            }
            variant="contained"
            disabled={bankAccountLoading || !bankAccountForm.isValid}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </DefaultDialog>
  )
}

ClientBankAccountForm.defaultProps = {
  open: false,
  clientProfileId: null,
  bankAccountData: null,
}

export default ClientBankAccountForm
