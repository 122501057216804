/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { companyGoal } from 'src/graphql/models/commissions'
import { useSnackbar } from 'notistack'
import { useAddOrUpdateCompanyGoal } from 'src/graphql/operations/mutations/commissions'
import { useFormik } from 'formik'
import { DefaultDialog } from 'src/components/Dialogs'
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import { maskMoney, unMaskMoney } from 'src/utils/masker'
import { Save } from '@mui/icons-material'
import { formatDate } from 'src/utils/date'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DatePicker } from 'everchain-uilibrary'

interface DefaultValuesProps {
  id: number | string | undefined
  amount: number | any
  startDate: Date | null
}

const initialValuesDefault = {
  id: '',
  amount: null,
  startDate: null,
}

interface CompanyGoalParam {
  companyGoal: companyGoal | undefined
  open?: boolean
  onClose: () => void
}

const formSchema = Yup.object().shape({
  amount: Yup.string().nullable().required('Required'),
  startDate: Yup.date().nullable().required('Required'),
})

const CompanyGoal: React.FC<CompanyGoalParam> = ({
  companyGoal,
  open = false,
  onClose,
}: CompanyGoalParam) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()

  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )
  const { AddOrUpdateCompanyGoal, loading: formLoading } =
    useAddOrUpdateCompanyGoal({
      onCompleted: () => {
        enqueueSnackbar('Company Goal saved successfully', notifySuccess)
        onClose()
      },
      onError: () => {
        enqueueSnackbar('You can not select a date in the past.', notifyError)
        onClose()
      },
    })

  const companyGoalForm = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id: values.id || null,
        amount: unMaskMoney(values?.amount),
        startDate: values.startDate
          ? new Date(formatDate(values.startDate))
          : null,
      }

      AddOrUpdateCompanyGoal({
        variables: {
          companyGoalRequest: {
            ...requestValues,
          },
        },
        refetchQueries: ['GetCompanyGoals'],
      })

      setSubmitting(false)
    },
  })
  useEffect(() => {
    if (companyGoal) {
      setInitialValues((prevState) => ({
        ...prevState,
        id: companyGoal.id || '',
        amount: companyGoal.amount || '',
        startDate: companyGoal.startDate || '',
      }))
    }
  }, [companyGoal])

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={`${companyGoal?.id ? 'Edit' : 'Add'} Company Goal`}
    >
      <form onSubmit={companyGoalForm.handleSubmit}>
        <DialogContent>
          <Box mb={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={({ target: { value, name } }) => {
                    const masked = maskMoney(value)
                    companyGoalForm.setFieldValue(name, masked, false)
                  }}
                  value={companyGoalForm.values.amount}
                  error={!!companyGoalForm.errors.amount}
                  helperText={<>{companyGoalForm.errors.amount}</>}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <DatePicker
                  name="startDate"
                  id="startDate"
                  label="Period"
                  views={['year', 'month']}
                  value={companyGoalForm.values.startDate}
                  errorMessage={companyGoalForm.errors.startDate}
                  onChange={(date) => {
                    if (date && date < actualDate) {
                      enqueueSnackbar(
                        'You can not select a date in the past.',
                        notifyError
                      )
                    } else {
                      companyGoalForm.setFieldValue('startDate', date, false)
                    }
                  }}
                  inputFormat="YYYY/MM"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            color="primary"
            type="submit"
            startIcon={formLoading ? <CircularProgress size={16} /> : <Save />}
            variant="contained"
            disabled={formLoading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </DefaultDialog>
  )
}

export default CompanyGoal
