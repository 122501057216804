import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { Save } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { GET_BANK_ACCOUNTS } from 'src/graphql/operations/queries/clientProfiles'
import { GetBankAccounts, GetClient } from 'src/graphql/models/clientProfiles'
import {
  useAcceptTemplateBankAccount,
  useSetBusinessDefaultBankAccount,
} from 'src/graphql/operations/mutations/businesses'
import { useSnackbar } from 'notistack'
import { ConfirmDialog } from '../Dialogs'

interface DefaultBankAccountProps {
  clientId: number
  businessId: string
  defaultId?: number
  isSeller: boolean
  client?: GetClient
}

const DefaultBankAccount = ({
  clientId,
  businessId,
  defaultId,
  isSeller,
  client,
}: DefaultBankAccountProps) => {
  const [bankAccountSelected, setBankAccountSelected] = useState<any>('')
  const [bankAccountSelectedTemp, setBankAccountSelectedTemp] =
    useState<any>('')
  const [bankAccount, setBankAccount] = useState<any>({})
  const countryCode = client?.countryCode || process.env.REACT_APP_COUNTRY

  const { enqueueSnackbar } = useSnackbar()

  const { data: getBankAccountData, loading: bankAccountLoading } =
    useQuery<GetBankAccounts>(GET_BANK_ACCOUNTS, {
      notifyOnNetworkStatusChange: true,
      variables: {
        request: {
          clientIdFilter: Number(clientId),
          statusFilter: 'Active',
        },
      },
      skip: !clientId,
    })

  const handleSetBankAccount = useCallback(
    (id: any) => {
      if (getBankAccountData?.getBankAccounts.length) {
        const bankAccountFounded = getBankAccountData.getBankAccounts.find(
          (bankAccountItem) => bankAccountItem.id === id
        )
        setBankAccount(bankAccountFounded)
      }
    },
    [getBankAccountData]
  )
  const handleAcceptTemplateCompleted = (data: any) => {}
  const { acceptTemplateBankAccount } = useAcceptTemplateBankAccount(
    handleAcceptTemplateCompleted
  )

  const handleSetBusinessCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Default Bank Account Has Been Successfully Set', {
        variant: 'success',
      })
    }
    if (defaultId && defaultId !== bankAccount.id) {
      acceptTemplateBankAccount({
        variables: {
          oldAccountId: Number(defaultId),
          newAccountId: Number(bankAccount.id),
        },
      })
    }
  }

  const { setBusinessDefaultBankAccount, loading } =
    useSetBusinessDefaultBankAccount(handleSetBusinessCompleted)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setBusinessDefaultBankAccount({
      variables: {
        businessId,
        bankAccountId: bankAccount.id,
      },
      refetchQueries: ['GetSeller', 'GetBuyer'],
    })
  }
  const [openChangeDialog, setOpenChangeDialog] = useState(false)
  useEffect(() => {
    if (defaultId) {
      handleSetBankAccount(defaultId)
      setBankAccountSelected(defaultId)
    }
  }, [handleSetBankAccount, setBankAccountSelected, defaultId])
  const handleClose = () => {
    setOpenChangeDialog(false)
  }
  const handleChange = (): void => {
    setOpenChangeDialog(false)
    handleSetBankAccount(bankAccountSelectedTemp)
    setBankAccountSelected(bankAccountSelectedTemp)
  }
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <ConfirmDialog
          open={openChangeDialog}
          title="Change Default Bank Account"
          description={
            <Box mb={3}>
              <Typography variant="body2" color="textSecondary">
                Do you want to update the bank account to all seller upload
                templates?
              </Typography>
            </Box>
          }
          closeName="No"
          confirmName="Yes"
          onClose={handleClose}
          onConfirm={handleChange}
        />
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            label="Default Bank Account"
            placeholder="Default Bank Account"
            value={bankAccountSelected}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const {
                target: { value },
              } = event
              if (isSeller && !!bankAccountSelected && !!value) {
                setOpenChangeDialog(true)
                setBankAccountSelectedTemp(value)
              } else {
                handleSetBankAccount(value)
                setBankAccountSelected(value)
              }
            }}
          >
            {!getBankAccountData?.getBankAccounts.length &&
              bankAccountLoading && <MenuItem value="">Loading</MenuItem>}
            {!getBankAccountData?.getBankAccounts.length &&
            !bankAccountLoading ? (
              <MenuItem value="">Bank Account Empty</MenuItem>
            ) : (
              getBankAccountData?.getBankAccounts.map((bankAccountItem) => (
                <MenuItem key={bankAccountItem.id} value={bankAccountItem.id}>
                  {bankAccountItem.bankName} -{' '}
                  {bankAccountItem.nameOnAccount
                    ? bankAccountItem.nameOnAccount
                    : 'N/A'}{' '}
                  -{' '}
                  {bankAccountItem.accountNumber
                    ? bankAccountItem.accountNumber.length > 4
                      ? bankAccountItem.accountNumber.slice(-4)
                      : bankAccountItem.accountNumber
                    : 'N/A'}
                </MenuItem>
              ))
            )}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="h6">Bank Name</Typography>
          <Typography>{bankAccount?.bankName}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="h6">
            {' '}
            {countryCode === 'UK' ? 'Sort Code' : 'Routing No.'}
          </Typography>
          <Typography>{bankAccount?.routingNumber}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="h6">Name On Account</Typography>
          <Typography>{bankAccount?.nameOnAccount || '-'}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="h6">Status</Typography>
          <Typography>{bankAccount?.status}</Typography>
        </Grid>
        {countryCode !== 'UK' && (
          <Grid item xs={6} sm={4} md={4}>
            <Typography variant="h6">Account Type</Typography>
            <Typography>{bankAccount?.accountTypeCode}</Typography>
          </Grid>
        )}
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="h6">Account No.</Typography>
          <Typography>{bankAccount?.accountNumber}</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="h6">Country</Typography>
          <Typography>{bankAccount?.countryCode}</Typography>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" my={6}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={!bankAccountSelected || bankAccountLoading || loading}
          startIcon={loading ? <CircularProgress size={16} /> : <Save />}
        >
          Save
        </Button>
      </Box>
    </form>
  )
}
DefaultBankAccount.defaultProps = {
  client: null,
  defaultId: null,
}
export default DefaultBankAccount
