import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import { Close } from '@mui/icons-material'
import { useIdleTimer } from 'react-idle-timer'
import AuthService from 'src/configs/AuthService'
import { DialogTitleStyled } from './style'

interface IdleModalProps {
  children: React.ReactNode
}

const IdleModal: React.FC<IdleModalProps> = ({ children }) => {
  const auth = useMemo(() => new AuthService(), [])
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [seconds, setSeconds] = useState(
    Number(process.env.REACT_APP_IDLE_NOTIFICATION_TIMEOUT_SECONDS)
  )

  useEffect(() => {
    const countdown = setInterval(() => {
      if (openContactDialog) {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          clearInterval(countdown)
          auth.logout()
        }
      } else {
        clearInterval(countdown)
        setSeconds(
          Number(process.env.REACT_APP_IDLE_NOTIFICATION_TIMEOUT_SECONDS)
        )
      }
    }, 1000)

    return () => {
      clearInterval(countdown)
    }
  }, [openContactDialog, seconds, auth])

  const handleOnIdle = (event: any) => {
    setOpenContactDialog(true)
  }

  useIdleTimer({
    timeout: 1000 * 60 * Number(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES),
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <>
      {children}
      <Dialog
        open={openContactDialog}
        onClose={() => setOpenContactDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            Session Inactivity
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenContactDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Typography component="div">
            Your session is about to end due to inactivity. Click{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              "Stay Signed In"
            </Box>{' '}
            to continue your session. You will be automatically signed out in{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              {seconds}
            </Box>{' '}
            seconds.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenContactDialog(false)
            }}
            variant="contained"
            color="primary"
          >
            Stay Signed In
          </Button>
          <Button
            onClick={() => {
              auth.logout()
            }}
            variant="contained"
          >
            Sign me out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default IdleModal
