import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { DialogTitleStyled } from './style'

interface ConfirmDialogProps {
  open: boolean
  onClose: () => void
  onConfirm?: () => void
  title: string
  description?: React.ReactElement
  closeName?: string
  confirmName?: string
  loading?: boolean
  children?: React.ReactElement | null
  hideConfirmButton?: boolean
  disableButtons?: boolean
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  closeName,
  confirmName,
  loading,
  children,
  hideConfirmButton,
  disableButtons,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitleStyled id="reject-dialog-title">
        <Typography
          variant="body1"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <IconButton
          data-cy="close-branch-modal-button"
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent>
        {description}
        {children && <>{children}</>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={disableButtons}>
          {closeName}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          disabled={loading || disableButtons}
          startIcon={loading && <CircularProgress size={15} color="primary" />}
          autoFocus
          style={{ display: hideConfirmButton ? 'none' : '' }}
        >
          {confirmName}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.defaultProps = {
  description: <div />,
  closeName: 'Cancel',
  confirmName: 'Ok',
  loading: false,
  children: null,
  hideConfirmButton: false,
  onConfirm: () => {},
  disableButtons: false,
}

export default ConfirmDialog
