import React from 'react'
import {
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { DialogTitleStyled } from './style'

interface FormDialogProps {
  title?: string
  contentText?: string
  Actions?: React.ReactNode
  children?: React.ReactNode
  onClose: () => void
  open: boolean
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined
}

const FormDialog: React.FC<FormDialogProps> = ({
  title,
  contentText,
  Actions,
  children,
  onClose,
  open,
  maxWidth,
}: FormDialogProps) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
    >
      {!!title && (
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
      )}
      <DialogContent>
        {!!contentText && <DialogContentText>{contentText}</DialogContentText>}
        {children}
      </DialogContent>
      {!!Actions && <DialogActions>{Actions}</DialogActions>}
    </Dialog>
  )
}

FormDialog.defaultProps = {
  title: '',
  contentText: '',
  Actions: null,
  children: null,
  maxWidth: 'sm',
}

export default FormDialog
