/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  FormHelperText,
  Autocomplete,
  Skeleton,
} from '@mui/material'

import { Save, List as ListIcon } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import hardCodeData from 'src/utils/hardcodeData'
import { getStateDropDownName, mapPropNullToString } from 'src/utils/common'
import { useHistory } from 'react-router-dom'
import { SERVICER_BUSINESS_MANAGE } from 'src/routes'
import { useSnackbar } from 'notistack'
import { DefaultDialog } from 'src/components/Dialogs'
import { BusinessNameHistory } from 'src/components/NameHistory'
import {
  CountryDataResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import {
  maskZipCode,
  maskPhoneNumber,
  unmaskPhoneNumber,
} from 'src/utils/masker'
import { useQuery } from '@apollo/client'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  PermissionCodeAccess,
  TENANT_TYPE,
  WEEKDAYS,
} from 'src/utils/constants'
import { format, parse } from 'date-fns'
import BusinessInformationSkeleton from 'src/components/BusinessSkeleton'
import { AbilityContext } from 'src/context/Can'
import { getLocaleDateString } from 'src/utils/date'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { DatePicker } from 'everchain-uilibrary'
import { MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { BusinessStatusInfo } from 'src/components/BusinessStatusInfo'
import { BusinessServicerRequestType } from 'src/infra/api/models/servicer'
import { useMutation } from '@tanstack/react-query'
import { postAddOrUpdateServicer } from 'src/infra/api/services/servicer'

interface ServicerBusinessInformationProps {
  servicerBusinessId?: string | undefined
  clientId: number
  servicerData?: BusinessServicerRequestType | undefined
  client?: GetClient
  servicerLoading?: boolean
}

const ServicerBusinessInformation = ({
  clientId,
  servicerBusinessId,
  servicerData,
  client,
  servicerLoading,
}: ServicerBusinessInformationProps) => {
  const { data: countryResponse, loading: loadingCountryData } =
    useQuery<CountryDataResponse>(GET_COUNTRY, {
      onCompleted: (data: CountryDataResponse) => {
        ServicerBusinessForm.setFieldValue(
          'countryCode',
          data.countryDataResponse[0].countryCode
        )
      },
    })

  const validate = (values: any) => {
    const errors: any = {}

    if (
      values.membershipEstablished &&
      !moment(
        values.membershipEstablished,
        formatInput.toUpperCase(),
        true
      ).isValid()
    ) {
      errors.membershipEstablished = 'Invalid date'
    }

    if (
      values.insurance_ExpirationDate &&
      !moment(
        values.insurance_ExpirationDate,
        formatInput.toUpperCase(),
        true
      ).isValid()
    ) {
      errors.insurance_ExpirationDate = 'Invalid date'
    }

    if (
      values.onSiteAudit &&
      !moment(values.onSiteAudit, formatInput.toUpperCase(), true).isValid()
    ) {
      errors.onSiteAudit = 'Invalid date'
    }

    return errors
  }

  const formatInput = getLocaleDateString()

  const country =
    countryResponse && countryResponse?.countryDataResponse[0].countryCode

  const showCountryOption =
    countryResponse && countryResponse?.countryDataResponse.length > 1

  const countryCode = client?.countryCode || process.env.REACT_APP_COUNTRY

  const initialDaysOfOperation = [
    WEEKDAYS[0],
    WEEKDAYS[1],
    WEEKDAYS[2],
    WEEKDAYS[3],
    WEEKDAYS[4],
  ]

  const validateZipCode = (zipCodeValue: string) => {
    if (ServicerBusinessForm.values.countryCode?.toLowerCase() === 'us') {
      const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
      return regex.test(zipCodeValue || '')
    }
    if (ServicerBusinessForm.values.countryCode?.toLowerCase() === 'uk') {
      const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      return regex.test(zipCodeValue || '')
    }
    if (ServicerBusinessForm.values.countryCode?.toLowerCase() === 'ca') {
      const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
      return regex.test(zipCodeValue || '')
    }
    return false
  }

  const ServicerBusinessSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    status: Yup.string().nullable().required('Required'),
    customerServiceEmail: Yup.string().nullable().email('Invalid email'),
    primaryContact_EMail: Yup.string().nullable().email('Invalid email'),
    startHoursOfOperation: Yup.string().nullable(),
    recertificationFrequency: Yup.number()
      .nullable()
      .min(1, 'Recertification frequency must be more than or equal to 1')
      .max(60, 'Recertification frequency must be less than or equal to 60'),
    endHoursOfOperation: Yup.string()
      .nullable()
      .test(
        'endLaterStart',
        'End time must be greater than start time',
        // eslint-disable-next-line func-names
        function (endHoursOfOperation) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.startHoursOfOperation
            ? parse(endHoursOfOperation || '', 'h:mm a', new Date()) >
                // eslint-disable-next-line react/no-this-in-sfc
                parse(this.parent.startHoursOfOperation, 'h:mm a', new Date())
            : true
        }
      ),
    postalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
    billingPostalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
  })

  const initialValueData = {
    name: '',
    legalName: '',
    status: 'Application In Process',
    startHoursOfOperation: null,
    endHoursOfOperation: null,
    street1: client?.street1 ?? '',
    street2: client?.street2 ?? '',
    city: client?.city ?? '',
    countryCode: client?.countryCode ?? country,
    stateCode: client?.stateCode ?? '',
    postalCode: client?.postalCode ?? '',
    billingStreet1: client?.street1 ?? '',
    billingStreet2: client?.street2 ?? '',
    billingCity: client?.city ?? '',
    billingStateCode: client?.stateCode ?? '',
    billingPostalCode: client?.postalCode ?? '',
    corpHQPhoneNumber: '',
    customerServicePhoneNumber: '',
    customerServiceEmail: '',
    primaryContact_LastName: '',
    primaryContact_FirstName: '',
    primaryContact_EMail: '',
    primaryContact_OfficePhone: '',
    primaryContact_MobilePhone: '',
    recertificationFrequency: null,
    membershipEstablished: null,
    onSiteAudit: null,
    companyWebsiteAddress: '',
    taxId: '',
    businessTypeLLC: '',
    stateOfIncorporation: '',
    dateOfEstablishment: null,
  }
  const ability = useContext(AbilityContext)
  const [initialValues, setInitialValues] = useState<any>(initialValueData)
  const [openNameHistory, setOpenNameHistory] = useState(false)
  const loading = false
  const { user, profileClient } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const history = useHistory()
  const [daysOfOperation, setDaysOfOperation] = useState(initialDaysOfOperation)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  const hasStatusPermission = ability.can(
    PermissionCodeAccess.CLIENT_STATUS_PERMISSION,
    'any'
  )
  const { enqueueSnackbar } = useSnackbar()
  const [addOrUpdateServicerLoading, setAddOrUpdateServicerLoading] =
    useState(false)

  const addOrUpdateServicerRequest = useMutation({
    mutationFn: (input: any) => {
      setAddOrUpdateServicerLoading(true)
      return postAddOrUpdateServicer(input)
    },
    onSuccess: (data: any) => {
      if (data) {
        const text = servicerBusinessId ? 'Update' : 'Create'
        enqueueSnackbar(`${text} servicer successful`, {
          variant: 'success',
        })
        if (data.addOrUpdateServicer && data.addOrUpdateServicer.id) {
          history.push(
            `${SERVICER_BUSINESS_MANAGE}/${clientId}/${data.addOrUpdateServicer.id}`
          )
        }
        setAddOrUpdateServicerLoading(false)
      }
    },
    onError: (error: any) => {
      const text = servicerBusinessId ? 'update' : 'create'
      enqueueSnackbar(`Failed t ${text} servicer successful`, {
        variant: 'error',
      })
      setAddOrUpdateServicerLoading(false)
    },
  })

  const ServicerBusinessForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ServicerBusinessSchema,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const servicerRequest = {
        name: values.name || null,
        status: values.status || null,
        legalName: values.legalName || null,
        startHoursOfOperation: values?.startHoursOfOperation,
        endHoursOfOperation: values?.endHoursOfOperation,
        daysOfOperation: daysOfOperation.map((x) => x.value),
        street1: values.street1 || null,
        street2: values.street2 || null,
        city: values.city || null,
        countryCode: values.countryCode || null,
        stateCode: values.stateCode || null,
        postalCode: values.postalCode || null,
        billingStreet1: values.billingStreet1 || null,
        billingStreet2: values.billingStreet2 || null,
        billingCity: values.billingCity || null,
        billingStateCode: values.billingStateCode || null,
        billingPostalCode: values.billingPostalCode || null,
        corpHQPhoneNumber: values.corpHQPhoneNumber
          ? unmaskPhoneNumber(values.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: values.customerServicePhoneNumber
          ? unmaskPhoneNumber(values.customerServicePhoneNumber, countryCode)
          : null,
        customerServiceEmail: values.customerServiceEmail || null,
        primaryContact_LastName: values.primaryContact_LastName || null,
        primaryContact_FirstName: values.primaryContact_FirstName || null,
        primaryContact_EMail: values.primaryContact_EMail || null,
        primaryContact_OfficePhone: values.primaryContact_OfficePhone
          ? unmaskPhoneNumber(values.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: values.primaryContact_MobilePhone
          ? unmaskPhoneNumber(values.primaryContact_MobilePhone, countryCode)
          : null,
        recertificationFrequency: values.recertificationFrequency || null,
        membershipEstablished: values.membershipEstablished
          ? new Date(values.membershipEstablished)
          : null,
        onSiteAudit: values.onSiteAudit ? new Date(values.onSiteAudit) : null,
        dateOfEstablishment: values.dateOfEstablishment
          ? new Date(values.dateOfEstablishment)
          : null,
        companyWebsiteAddress: values.companyWebsiteAddress || null,
        businessTypeLLC: values.businessTypeLLC || null,
        taxId: values.taxId || null,
        stateOfIncorporation: values.stateOfIncorporation || null,
      }

      if (servicerBusinessId) {
        addOrUpdateServicerRequest.mutate({
          servicer: {
            id: servicerBusinessId,
            clientId: Number(clientId),
            ...servicerRequest,
          },
        })
      } else {
        addOrUpdateServicerRequest.mutate({
          servicer: {
            clientId: Number(clientId),
            ...servicerRequest,
          },
        })
      }
    },
  })

  const handleNameHistoryToogle = () => {
    setOpenNameHistory(!openNameHistory)
  }

  const handleCountryChange = (value: any, name: any) => {
    ServicerBusinessForm.setFieldValue('stateCode', null, true)
    ServicerBusinessForm.setFieldValue('billingStateCode', null, true)
    ServicerBusinessForm.setFieldValue('postalCode', '', true)
    ServicerBusinessForm.setFieldValue('billingPostalCode', '', true)
    ServicerBusinessForm.setFieldValue(name, value, true)
  }

  useEffect(() => {
    if (servicerData) {
      setInitialValues((prevState: BusinessServicerRequestType) => ({
        ...prevState,
        ...mapPropNullToString(servicerData),
        corpHQPhoneNumber: servicerData.corpHQPhoneNumber
          ? maskPhoneNumber(servicerData.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: servicerData.customerServicePhoneNumber
          ? maskPhoneNumber(
              servicerData.customerServicePhoneNumber,
              countryCode
            )
          : null,
        primaryContact_OfficePhone: servicerData.primaryContact_OfficePhone
          ? maskPhoneNumber(
              servicerData.primaryContact_OfficePhone,
              countryCode
            )
          : null,
        primaryContact_MobilePhone: servicerData.primaryContact_MobilePhone
          ? maskPhoneNumber(
              servicerData.primaryContact_MobilePhone,
              countryCode
            )
          : null,
        onSiteAudit: servicerData.onSiteAudit
          ? new Date(servicerData.onSiteAudit)
          : null,
        membershipEstablished: servicerData.membershipEstablished
          ? new Date(servicerData.membershipEstablished)
          : null,
        dateOfEstablishment: servicerData.dateOfEstablishment
          ? new Date(servicerData.dateOfEstablishment)
          : null,
      }))
      setDaysOfOperation(
        servicerData.daysOfOperation.length > 0
          ? WEEKDAYS.filter(
              (x) => servicerData.daysOfOperation.indexOf(x.value) !== -1
            )
          : initialDaysOfOperation
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicerData])

  const onChangeTime = (date: any, field: string) => {
    ServicerBusinessForm.setFieldValue(
      field,
      format(new Date(date), 'hh:mm a'),
      false
    )
  }

  if (servicerLoading) {
    return <BusinessInformationSkeleton />
  }

  return (
    <form onSubmit={ServicerBusinessForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Servicer Business Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.name}
              value={ServicerBusinessForm.values.name || ''}
              helperText={<>{ServicerBusinessForm.errors.name}</>}
              disabled={!isInternal || !hasBasicInfoPermission}
              InputProps={{
                endAdornment: servicerBusinessId && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleNameHistoryToogle()
                      }}
                    >
                      <ListIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} display="flex" flexDirection="row">
            <TextField
              fullWidth
              label="Status"
              select
              name="status"
              disabled={!isInternal || !hasStatusPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.status}
              value={ServicerBusinessForm.values.status}
              helperText={<>{ServicerBusinessForm.errors.status}</>}
            >
              {hardCodeData
                .getBusinessStatus(ServicerBusinessForm.values.businessType)
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>
            <BusinessStatusInfo
              businessType={ServicerBusinessForm.values.businessType}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Legal Name"
              name="legalName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.legalName}
              value={ServicerBusinessForm.values.legalName || ''}
              helperText={<>{ServicerBusinessForm.errors.legalName}</>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-companyWebsiteAddress"
              label="Company Website Address"
              name="companyWebsiteAddress"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={ServicerBusinessForm.handleChange}
              value={ServicerBusinessForm.values.companyWebsiteAddress || ''}
              error={!!ServicerBusinessForm.errors.companyWebsiteAddress}
              helperText={
                <>{ServicerBusinessForm.errors.companyWebsiteAddress}</>
              }
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-stateofIncorporation"
              label="State of Incorporation"
              name="stateOfIncorporation"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={ServicerBusinessForm.handleChange}
              value={ServicerBusinessForm.values.stateOfIncorporation || ''}
              error={!!ServicerBusinessForm.errors.stateOfIncorporation}
              helperText={
                <>{ServicerBusinessForm.errors.stateOfIncorporation}</>
              }
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-taxID"
              label={'Tax ID #'}
              name="taxId"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 20,
              }}
              onChange={ServicerBusinessForm.handleChange}
              value={ServicerBusinessForm.values.taxId || ''}
              error={!!ServicerBusinessForm.errors.taxId}
              helperText={<>{ServicerBusinessForm.errors.taxId}</>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={!isInternal || !hasBasicInfoPermission}
              fullWidth
              data-cy="dialog-input-field-businessType"
              label={'Business Type (LLC)'}
              name="businessTypeLLC"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={ServicerBusinessForm.handleChange}
              value={ServicerBusinessForm.values.businessTypeLLC || ''}
              error={!!ServicerBusinessForm.errors.businessTypeLLC}
              helperText={<>{ServicerBusinessForm.errors.businessTypeLLC}</>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              label="Date of Establishment"
              disabled={!isInternal || !hasBasicInfoPermission}
              name="dateOfEstablishment"
              onChange={(date) => {
                ServicerBusinessForm.setFieldValue(
                  'dateOfEstablishment',
                  date,
                  false
                )
              }}
              value={ServicerBusinessForm.values.dateOfEstablishment}
              errorMessage={ServicerBusinessForm.errors.dateOfEstablishment?.toString()}
              country={profileClient?.Country}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Hours of Operation (PST)</Typography>
        <Grid
          container
          spacing={8}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={3} lg={3}>
            <MobileTimePicker
              label="Start"
              disabled={!isInternal || !hasBasicInfoPermission}
              value={
                ServicerBusinessForm.values.startHoursOfOperation
                  ? dayjs(
                      parse(
                        ServicerBusinessForm.values.startHoursOfOperation,
                        'h:mm a',
                        new Date()
                      )
                    )
                  : ServicerBusinessForm.values.startHoursOfOperation
              }
              onChange={(date) => onChangeTime(date, 'startHoursOfOperation')}
              ampm
              minutesStep={30}
              orientation="landscape"
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <MobileTimePicker
              label="End"
              disabled={!isInternal || !hasBasicInfoPermission}
              value={
                ServicerBusinessForm.values.endHoursOfOperation
                  ? dayjs(
                      parse(
                        ServicerBusinessForm.values.endHoursOfOperation,
                        'h:mm a',
                        new Date()
                      )
                    )
                  : ServicerBusinessForm.values.endHoursOfOperation
              }
              onChange={(date) => onChangeTime(date, 'endHoursOfOperation')}
              ampm
              minutesStep={30}
              orientation="landscape"
            />
            {ServicerBusinessForm.errors.endHoursOfOperation && (
              <FormHelperText style={{ color: '#d32f2f' }}>
                <>{ServicerBusinessForm.errors.endHoursOfOperation}</>
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} lg={6}>
            <Autocomplete
              id="daysOfOperation"
              multiple
              options={WEEKDAYS}
              disabled={!isInternal || !hasBasicInfoPermission}
              getOptionLabel={(option) => option.day}
              value={daysOfOperation}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  disabled={!isInternal || !hasBasicInfoPermission}
                  name="daysOfOperation"
                  label="Days of the Week"
                  placeholder="Chose one or more days of the week"
                  error={!!ServicerBusinessForm.errors.daysOfOperation}
                />
              )}
              onChange={(ev, values) => {
                values && values.length > 0
                  ? setDaysOfOperation(values)
                  : enqueueSnackbar('You need to select at least one weekday', {
                      variant: 'error',
                    })
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Address</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street"
              name="street1"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.street1}
              value={ServicerBusinessForm.values.street1 || ''}
              helperText={<>{ServicerBusinessForm.errors.street1}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street 2"
              name="street2"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.street2}
              value={ServicerBusinessForm.values.street2 || ''}
              helperText={<>{ServicerBusinessForm.errors.street2}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="City"
              name="city"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.city}
              value={ServicerBusinessForm.values.city || ''}
              helperText={<>{ServicerBusinessForm.errors.city}</>}
            />
          </Grid>
          {showCountryOption && (
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label="Country"
                  name="countryCode"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={({ target: { value, name } }) => {
                    handleCountryChange(value, name)
                  }}
                  error={!!ServicerBusinessForm.errors.countryCode}
                  value={ServicerBusinessForm.values.countryCode}
                  helperText={<>{ServicerBusinessForm.errors.countryCode}</>}
                >
                  {countryResponse?.countryDataResponse.map((option) => (
                    <MenuItem
                      key={option.countryCode}
                      value={option.countryCode}
                    >
                      {option.countryDescription}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          )}
          <Grid item xs={12} lg={4}>
            {loadingCountryData ? (
              <Skeleton variant="rectangular" width="100%" />
            ) : (
              <TextField
                fullWidth
                label={getStateDropDownName(country)}
                name="stateCode"
                select
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={ServicerBusinessForm.handleChange}
                error={!!ServicerBusinessForm.errors.stateCode}
                value={ServicerBusinessForm.values.stateCode || ''}
                helperText={<>{ServicerBusinessForm.errors.stateCode}</>}
              >
                {countryResponse?.countryDataResponse
                  .find(
                    (x) =>
                      x.countryCode === ServicerBusinessForm.values.countryCode
                  )
                  ?.stateInfo.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.description}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Postal Code"
              name="postalCode"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={({ target: { value, name } }) => {
                const masked = maskZipCode(
                  value,
                  ServicerBusinessForm.values.countryCode
                )
                ServicerBusinessForm.setFieldValue(name, masked, true)
              }}
              error={!!ServicerBusinessForm.errors.postalCode}
              value={ServicerBusinessForm.values.postalCode || ''}
              helperText={<>{ServicerBusinessForm.errors.postalCode}</>}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Billing Address</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street"
              name="billingStreet1"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.billingStreet1}
              value={ServicerBusinessForm.values.billingStreet1 || ''}
              helperText={<>{ServicerBusinessForm.errors.billingStreet1}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Street 2"
              name="billingStreet2"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.billingStreet2}
              value={ServicerBusinessForm.values.billingStreet2 || ''}
              helperText={<>{ServicerBusinessForm.errors.billingStreet2}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="City"
              name="billingCity"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.billingCity}
              value={ServicerBusinessForm.values.billingCity || ''}
              helperText={<>{ServicerBusinessForm.errors.billingCity}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            {loadingCountryData ? (
              <Skeleton variant="rectangular" width="100%" />
            ) : (
              <TextField
                fullWidth
                label={getStateDropDownName(country)}
                name="billingStateCode"
                select
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={ServicerBusinessForm.handleChange}
                error={!!ServicerBusinessForm.errors.billingStateCode}
                value={ServicerBusinessForm.values.billingStateCode}
                helperText={<>{ServicerBusinessForm.errors.billingStateCode}</>}
              >
                {countryResponse?.countryDataResponse
                  .find(
                    (x) =>
                      x.countryCode === ServicerBusinessForm.values.countryCode
                  )
                  ?.stateInfo.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.description}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Postal Code"
              name="billingPostalCode"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={({ target: { value, name } }) => {
                const masked = maskZipCode(
                  value,
                  ServicerBusinessForm.values.countryCode
                )
                ServicerBusinessForm.setFieldValue(name, masked, true)
              }}
              error={!!ServicerBusinessForm.errors.billingPostalCode}
              value={ServicerBusinessForm.values.billingPostalCode || ''}
              helperText={<>{ServicerBusinessForm.errors.billingPostalCode}</>}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Buyer Contact Information</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="HQ Phone"
              name="corpHQPhoneNumber"
              disabled={!isInternal || !hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                ServicerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!ServicerBusinessForm.errors.corpHQPhoneNumber}
              value={ServicerBusinessForm.values.corpHQPhoneNumber || ''}
              helperText={<>{ServicerBusinessForm.errors.corpHQPhoneNumber}</>}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Customer Services Phone"
              name="customerServicePhoneNumber"
              disabled={!isInternal || !hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                ServicerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!ServicerBusinessForm.errors.customerServicePhoneNumber}
              value={
                ServicerBusinessForm.values.customerServicePhoneNumber || ''
              }
              helperText={
                <>{ServicerBusinessForm.errors.customerServicePhoneNumber}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Customer Services E-mail"
              name="customerServiceEmail"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.customerServiceEmail}
              value={ServicerBusinessForm.values.customerServiceEmail || ''}
              helperText={
                <>{ServicerBusinessForm.errors.customerServiceEmail}</>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Primary Contact</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Last Name"
              name="primaryContact_LastName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.primaryContact_LastName}
              value={ServicerBusinessForm.values.primaryContact_LastName || ''}
              helperText={
                <>{ServicerBusinessForm.errors.primaryContact_LastName}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="First Name"
              name="primaryContact_FirstName"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.primaryContact_FirstName}
              value={ServicerBusinessForm.values.primaryContact_FirstName || ''}
              helperText={
                <>{ServicerBusinessForm.errors.primaryContact_FirstName}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="E-mail"
              name="primaryContact_EMail"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.primaryContact_EMail}
              value={ServicerBusinessForm.values.primaryContact_EMail || ''}
              helperText={
                <>{ServicerBusinessForm.errors.primaryContact_EMail}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Direct (Phone)"
              name="primaryContact_OfficePhone"
              disabled={!isInternal || !hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                ServicerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!ServicerBusinessForm.errors.primaryContact_OfficePhone}
              value={
                ServicerBusinessForm.values.primaryContact_OfficePhone || ''
              }
              helperText={
                <>{ServicerBusinessForm.errors.primaryContact_OfficePhone}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Mobile"
              name="primaryContact_MobilePhone"
              disabled={!isInternal || !hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, countryCode)
                ServicerBusinessForm.setFieldValue(name, masked, false)
              }}
              error={!!ServicerBusinessForm.errors.primaryContact_MobilePhone}
              value={
                ServicerBusinessForm.values.primaryContact_MobilePhone || ''
              }
              helperText={
                <>{ServicerBusinessForm.errors.primaryContact_MobilePhone}</>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={12}>
        <Typography variant="h6">Compliance</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              type="number"
              label="Recertification Frequency (Months)"
              name="recertificationFrequency"
              disabled={!isInternal || !hasBasicInfoPermission}
              onChange={ServicerBusinessForm.handleChange}
              error={!!ServicerBusinessForm.errors.recertificationFrequency}
              value={ServicerBusinessForm.values.recertificationFrequency || ''}
              helperText={
                <>{ServicerBusinessForm.errors.recertificationFrequency}</>
              }
              inputProps={{
                max: '999',
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DatePicker
              name="membershipEstablished"
              id="membershipEstablished"
              label="Membership Established"
              disabled={!isInternal || !hasBasicInfoPermission}
              minDate={
                new Date(ServicerBusinessForm.values.membershipEstablished)
              }
              value={ServicerBusinessForm.values.membershipEstablished}
              errorMessage={ServicerBusinessForm.errors.membershipEstablished?.toString()}
              onChange={(date) => {
                ServicerBusinessForm.setFieldValue(
                  'membershipEstablished',
                  date,
                  false
                )
              }}
              country={profileClient?.Country}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DatePicker
              name="onSiteAudit"
              id="onSiteAudit"
              label="On Site Audit"
              disabled={!isInternal || !hasBasicInfoPermission}
              minDate={new Date(ServicerBusinessForm.values.onSiteAudit)}
              value={ServicerBusinessForm.values.onSiteAudit}
              errorMessage={ServicerBusinessForm.errors.onSiteAudit?.toString()}
              onChange={(date) => {
                ServicerBusinessForm.setFieldValue('onSiteAudit', date, false)
              }}
              country={profileClient?.Country}
            />
          </Grid>
        </Grid>
      </Box>
      {(hasStatusPermission || (isInternal && hasBasicInfoPermission)) && (
        <Box display="flex" justifyContent="flex-end" my={2}>
          <Button
            startIcon={
              !addOrUpdateServicerLoading ? (
                <Save />
              ) : (
                <CircularProgress size={16} />
              )
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || addOrUpdateServicerLoading}
          >
            Save
          </Button>
        </Box>
      )}
      <DefaultDialog
        title="Name History"
        open={openNameHistory}
        onClose={handleNameHistoryToogle}
      >
        <DialogContent>
          {servicerBusinessId && (
            <BusinessNameHistory
              businessId={servicerBusinessId}
              refetchQueriesRest="getServicerDetails"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNameHistoryToogle}
          >
            Close
          </Button>
        </DialogActions>
      </DefaultDialog>
    </form>
  )
}

export default ServicerBusinessInformation
