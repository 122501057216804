import React, { useState, useEffect, useContext } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  DialogContent,
  DialogActions,
} from '@mui/material'

import { Save } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { DefaultDialog } from 'src/components/Dialogs'
import { BusinessNameHistory } from 'src/components/NameHistory'
import { maskPhoneNumber, unmaskPhoneNumber } from 'src/utils/masker'
import { GET_BUSINESS_RECOVERY_SETTINGS } from 'src/graphql/operations/queries/business'
import { useQuery } from '@apollo/client'
import { BusinessRecoverySettingsResponse } from 'src/graphql/models/businessRecovery'
import { mapPropNullToString } from 'src/utils/common'
import { useAddOrUpdateBusinessSettings } from 'src/graphql/operations/mutations/businesses'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { GetClient } from 'src/graphql/models/clientProfiles'

interface BusinessRecoverySettingsProps {
  businessId?: string | undefined
  client?: GetClient
}

const BusinessRecoverySettingsSchema = Yup.object().shape({
  contactEmail: Yup.string().nullable().email('Invalid email'),
})

const BusinessRecoverySettings = ({
  businessId,
  client,
}: BusinessRecoverySettingsProps) => {
  const { data: getRecoverySettings } =
    useQuery<BusinessRecoverySettingsResponse>(GET_BUSINESS_RECOVERY_SETTINGS, {
      variables: {
        businessId,
      },
      skip: !businessId,
    })

  const country = client?.countryCode || process.env.REACT_APP_COUNTRY

  const initialValueData = {
    id: getRecoverySettings?.businessRecoverySettings?.id,
    businessId,
    contactLastName:
      getRecoverySettings?.businessRecoverySettings?.contactLastName,
    contactFirstName:
      getRecoverySettings?.businessRecoverySettings?.contactFirstName,
    contactEMail: getRecoverySettings?.businessRecoverySettings?.contactEmail,
    contactOfficePhone: getRecoverySettings?.businessRecoverySettings
      ?.contactOfficePhone
      ? maskPhoneNumber(
          getRecoverySettings?.businessRecoverySettings?.contactOfficePhone,
          country
        )
      : null,
    contactMobilePhone: getRecoverySettings?.businessRecoverySettings
      ?.contactMobilePhone
      ? maskPhoneNumber(
          getRecoverySettings?.businessRecoverySettings?.contactMobilePhone,
          country
        )
      : null,
    isVendor: null,
    isCreditor: null,
  }
  const [initialValues, setInitialValues] = useState<any>(initialValueData)
  const [openNameHistory, setOpenNameHistory] = useState(false)
  const loading = false
  const ability = useContext(AbilityContext)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )

  const { enqueueSnackbar } = useSnackbar()

  const handleUpdateBusinessSettingsCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Business settings updated  successful', {
        variant: 'success',
      })
    }
  }

  const {
    addOrUpdateBusinessSettings,
    loading: updateBusinessSettingsLoading,
  } = useAddOrUpdateBusinessSettings(handleUpdateBusinessSettingsCompleted)

  const BusinessRecoverySettingsForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: BusinessRecoverySettingsSchema,

    onSubmit: (values, { setSubmitting }) => {
      const businessRecoverySettingsRequest = {
        id: values.id,
        businessId: values.businessId,
        contactLastName: values.contactLastName || null,
        contactFirstName: values.contactFirstName || null,
        contactEmail: values.contactEmail || null,
        contactOfficePhone: values.contactOfficePhone
          ? unmaskPhoneNumber(values.contactOfficePhone, country)
          : null,
        contactMobilePhone: values.contactMobilePhone
          ? unmaskPhoneNumber(values.contactMobilePhone, country)
          : null,
        isVendor: undefined,
        isCreditor: undefined,
      }
      addOrUpdateBusinessSettings({
        variables: {
          request: businessRecoverySettingsRequest,
        },
        refetchQueries: ['GetBusinessRecoverySettings'],
      })
    },
  })

  const handleNameHistoryToogle = () => {
    setOpenNameHistory(!openNameHistory)
  }

  useEffect(() => {
    if (getRecoverySettings) {
      setInitialValues((prevState: BusinessRecoverySettingsResponse) => ({
        ...prevState,
        ...mapPropNullToString(getRecoverySettings?.businessRecoverySettings),
      }))
    }
  }, [getRecoverySettings])

  return (
    <form onSubmit={BusinessRecoverySettingsForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Recovery Contact</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Last Name"
              name="contactLastName"
              disabled={!hasBasicInfoPermission}
              onChange={BusinessRecoverySettingsForm.handleChange}
              error={!!BusinessRecoverySettingsForm.errors.contactLastName}
              value={BusinessRecoverySettingsForm.values.contactLastName || ''}
              helperText={
                <>{BusinessRecoverySettingsForm.errors.contactLastName}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="First Name"
              name="contactFirstName"
              disabled={!hasBasicInfoPermission}
              onChange={BusinessRecoverySettingsForm.handleChange}
              error={!!BusinessRecoverySettingsForm.errors.contactFirstName}
              value={BusinessRecoverySettingsForm.values.contactFirstName || ''}
              helperText={
                <>{BusinessRecoverySettingsForm.errors.contactFirstName}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="E-mail"
              name="contactEmail"
              disabled={!hasBasicInfoPermission}
              onChange={BusinessRecoverySettingsForm.handleChange}
              error={!!BusinessRecoverySettingsForm.errors.contactEmail}
              value={BusinessRecoverySettingsForm.values.contactEmail || ''}
              helperText={
                <>{BusinessRecoverySettingsForm.errors.contactEmail}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Direct (Phone)"
              name="contactOfficePhone"
              disabled={!hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                BusinessRecoverySettingsForm.setFieldValue(name, masked, false)
              }}
              error={!!BusinessRecoverySettingsForm.errors.contactOfficePhone}
              value={
                BusinessRecoverySettingsForm.values.contactOfficePhone || ''
              }
              helperText={
                <>{BusinessRecoverySettingsForm.errors.contactOfficePhone}</>
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Mobile"
              name="contactMobilePhone"
              disabled={!hasBasicInfoPermission}
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                BusinessRecoverySettingsForm.setFieldValue(name, masked, false)
              }}
              error={!!BusinessRecoverySettingsForm.errors.contactMobilePhone}
              value={
                BusinessRecoverySettingsForm.values.contactMobilePhone || ''
              }
              helperText={
                <>{BusinessRecoverySettingsForm.errors.contactMobilePhone}</>
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Button
          startIcon={
            !updateBusinessSettingsLoading ? (
              <Save />
            ) : (
              <CircularProgress size={16} />
            )
          }
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            loading || updateBusinessSettingsLoading || !hasBasicInfoPermission
          }
        >
          Save
        </Button>
      </Box>
      <DefaultDialog
        title="Name History"
        open={openNameHistory}
        onClose={handleNameHistoryToogle}
      >
        <DialogContent>
          {businessId && (
            <BusinessNameHistory
              businessId={businessId}
              refetchQueries={['GetBuyer']}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!hasBasicInfoPermission}
            variant="outlined"
            color="primary"
            onClick={handleNameHistoryToogle}
          >
            Close
          </Button>
        </DialogActions>
      </DefaultDialog>
    </form>
  )
}

BusinessRecoverySettings.defaultProps = {
  businessId: null,
  client: null,
}

export default BusinessRecoverySettings
