/* eslint-disable react/require-default-props */
import { useLazyQuery } from '@apollo/client'
import {
  Box,
  DialogContent,
  Grid,
  Theme,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material'
import {
  Check,
  Close,
  GetApp,
  HelpOutline,
  InfoOutlined,
  SwapHoriz,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import BCOTooltip from 'src/components/BCOTooltip'
import { DefaultDialog } from 'src/components/Dialogs'
import {
  BusinessFileUri,
  BuyerBcoDataResponseType,
} from 'src/graphql/models/buyer'
import { GET_BUSINESS_FILE_URI } from 'src/graphql/operations/queries/buyer'
import { dynamicColors, getCountryCurrency } from 'src/utils/common'
import hardCodeData from 'src/utils/hardcodeData'
import { maskMoney } from 'src/utils/masker'
import { LineChart } from 'src/components/Charts'
import { ChartOptions } from 'chart.js'
import { makeStyles } from '@mui/styles'

const useStylesTooltip = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
  },
}))

const HtmlTooltip = ({
  children,
  title,
  placement,
}: {
  children: React.ReactElement<any, any>
  title: React.ReactNode
  placement?: 'right-start'
}) => {
  const classes = useStylesTooltip()
  return (
    <Tooltip title={title} className={classes.tooltip} placement={placement}>
      {children}
    </Tooltip>
  )
}

const borderColorLineOne = dynamicColors({
  min: 0,
  max: 245,
  opacity: 0.8,
})

interface BCOProps {
  buyerBCOData: BuyerBcoDataResponseType | undefined
  onClose: () => void
  open: boolean
  country: string
  clientCountry?: string | undefined
}

const BCOPreview = ({
  buyerBCOData,
  onClose,
  open,
  country,
  clientCountry,
}: BCOProps) => {
  const [agencies, setAgencies] = useState<any>({})
  const [officerBackground, setOfficerBackground] = useState<any>({})
  const [licensing, setLicensing] = useState<any>({})
  const [financial, setFinancial] = useState<any>({})
  const [fileType, setFileType] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const buyerComplianceRatiosDataReduce = (data: any[] = []) => {
    const defaultData: any = {
      labels: [],
      datasets: [
        {
          data: [],
          label: 'Account Complaint Ratio',
          fill: false,
          borderColor: borderColorLineOne,
        },
      ],
    }

    if (data && data.length) {
      return data.reduce((acc: any, curr: any) => {
        const labels = [...acc.labels, curr.key]
        const datasets = [...acc.datasets]

        if (datasets[0]) {
          datasets[0] = {
            ...datasets[0],
            data: [...datasets[0].data, curr.value <= 1 ? curr.value : 1],
          }
        }

        return {
          labels,
          datasets,
        }
      }, defaultData)
    }

    return defaultData
  }

  const lineOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const label = tooltipItem?.formattedValue || ''

            if (label < 0) {
              return 'N/A - Due to low volume of accounts purchased the account complaint ratio is not calculated.'
            }
            const formattedValue = parseFloat(label).toFixed(2)
            return formattedValue === '-0.00' ? '0.00' : formattedValue
          },
        },
      },
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        max: 1,
        min: 0,
        ticks: {
          stepSize: 0.2,
        },
      },
    },
  }
  const countryCode = country || clientCountry || process.env.REACT_APP_COUNTRY
  useEffect(() => {
    if (buyerBCOData) {
      setAgencies(
        hardCodeData
          .getAgenciesCertified()
          .find((c) => c.value === buyerBCOData.bcoAgenciesCertified)
      )
      setOfficerBackground(
        hardCodeData
          .getBackgroundChecks()
          .find((c) => c.value === buyerBCOData.bcoOfficerBackgroundChecks)
      )
      setLicensing(
        hardCodeData
          .getLicenseAttestation()
          .find((c) => c.value === buyerBCOData.bcoAttestation)
      )
      setFinancial(
        hardCodeData
          .getFinancialStatements()
          .find((c) => c.value === buyerBCOData.bcoFinancials)
      )
    }
  }, [buyerBCOData])

  const [getUri, { data: getBusinessFileUri }] = useLazyQuery<BusinessFileUri>(
    GET_BUSINESS_FILE_URI,
    {
      variables: {
        businessId: buyerBCOData?.buyerId,
        fileType,
        outputFileName: fileName,
      },
    }
  )

  const onDownload = (type: any, name: any) => {
    setFileType(type as string)
    setFileName(name as string)
    getUri()
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  useEffect(() => {
    const uri = getBusinessFileUri?.getBusinessFileTempUri ?? ''
    if (uri !== '') {
      downloadFile(uri)
    }
  }, [getBusinessFileUri])

  if (!buyerBCOData) {
    return <></>
  }

  return (
    <DefaultDialog
      title="Buyer Compliance Overview (BCO)"
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent>
        <Box style={{ marginTop: 15 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={5}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  mb={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Typography variant="h1" style={{ fontWeight: 500 }}>
                    {buyerBCOData.buyerName}
                  </Typography>
                  <Typography variant="caption">
                    {' '}
                    {buyerBCOData.buyerAddress}{' '}
                  </Typography>
                  <Typography variant="caption">
                    {' '}
                    {buyerBCOData.buyerCityStateZip}{' '}
                  </Typography>
                </Box>
                <Box
                  mb={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h4" style={{ fontWeight: 500 }}>
                    {buyerBCOData.buyerBcr}
                  </Typography>
                  <Box display="flex">
                    <Box display="flex" alignItems="bottom">
                      <Typography variant="caption">
                        {' '}
                        Account Complaint Ratio (ACR)
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <HtmlTooltip
                        placement="right-start"
                        title="Number of complaints per 5,000 accounts."
                      >
                        <Typography>
                          <HelpOutline fontSize="inherit" />
                        </Typography>
                      </HtmlTooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={7} style={{ minHeight: '200px' }}>
              <Box mb={2} minHeight="100px" minWidth="100px">
                <LineChart
                  data={buyerComplianceRatiosDataReduce(
                    buyerBCOData.buyerMonthlyBcr
                  )}
                  options={lineOptions}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Box display="flex" alignItems="bottom">
                  <Typography variant="caption">
                    {' '}
                    12 Month Rolling Complaint Ratio
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <HtmlTooltip
                    title={
                      <span>
                        <strong>Account Compaint Ratio (ACR)</strong> plotted
                        accross the last twelve (12) months.
                      </span>
                    }
                  >
                    <Typography>
                      <HelpOutline fontSize="inherit" />
                    </Typography>
                  </HtmlTooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Box
                mb={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{
                  borderLeft: '5px solid',
                  borderLeftColor: agencies.color,
                }}
              >
                <Box display="flex" flexDirection="column" marginLeft="10px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <Typography variant="subtitle2" color="secondary">
                      {agencies.label}
                    </Typography>
                    <Box display="flex">
                      <Box display="flex" alignItems="bottom">
                        <Typography variant="caption">
                          {' '}
                          Agencies Certified
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mr={2}>
                  <HtmlTooltip
                    title={
                      <BCOTooltip
                        title="Agencies Certified"
                        bcoTypes={hardCodeData.getAgenciesCertified()}
                      />
                    }
                  >
                    <Typography>
                      {agencies.icon ? (
                        agencies.icon === 'check' ? (
                          <Check fontSize="large" color="primary" />
                        ) : agencies.icon === 'arrows-swap' ? (
                          <SwapHoriz
                            fontSize="large"
                            style={{ color: 'gold' }}
                          />
                        ) : agencies.icon === 'close' ? (
                          <Close fontSize="large" style={{ color: 'red' }} />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}{' '}
                    </Typography>
                  </HtmlTooltip>
                </Box>
              </Box>
              <Box
                mb={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{
                  borderLeft: '5px solid',
                  borderLeftColor: officerBackground.color,
                }}
              >
                <Box display="flex" flexDirection="column" marginLeft="10px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <Typography variant="subtitle2" color="secondary">
                      {officerBackground.label}
                    </Typography>
                    <Box display="flex">
                      <Box display="flex" alignItems="bottom">
                        <Typography variant="caption">
                          {' '}
                          Officer Background Checks
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mr={2}>
                  <HtmlTooltip
                    title={
                      <BCOTooltip
                        title="Officer Background Checks"
                        bcoTypes={hardCodeData.getBackgroundChecks()}
                      />
                    }
                  >
                    <Typography>
                      {officerBackground.icon ? (
                        officerBackground.icon === 'check' ? (
                          <Check fontSize="large" color="primary" />
                        ) : officerBackground.icon === 'arrows-swap' ? (
                          <SwapHoriz
                            fontSize="large"
                            style={{ color: 'gold' }}
                          />
                        ) : officerBackground.icon === 'close' ? (
                          <Close fontSize="large" style={{ color: 'red' }} />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}{' '}
                    </Typography>
                  </HtmlTooltip>
                </Box>
              </Box>
              <Box
                mb={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{
                  borderLeft: '5px solid',
                  borderLeftColor: licensing.color,
                }}
              >
                <Box display="flex" flexDirection="column" marginLeft="10px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <Typography variant="subtitle2" color="secondary">
                      {licensing.label}
                    </Typography>
                    <Box display="flex">
                      <Box display="flex" alignItems="bottom">
                        <Typography variant="caption">
                          {' '}
                          Licensing Attestation
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      onDownload(
                        buyerBCOData.ccoAttestation.fileType,
                        buyerBCOData.ccoAttestation.blobUri
                      )
                    }}
                    color="secondary"
                  >
                    <GetApp style={{ color: licensing.color }} />
                  </IconButton>
                </Box>
                <Box mr={2}>
                  <HtmlTooltip
                    title={
                      <BCOTooltip
                        title="Licensing Attestation"
                        bcoTypes={hardCodeData.getLicenseAttestation()}
                      />
                    }
                  >
                    <Typography>
                      {licensing.icon ? (
                        licensing.icon === 'check' ? (
                          <Check fontSize="large" color="primary" />
                        ) : licensing.icon === 'arrows-swap' ? (
                          <SwapHoriz
                            fontSize="large"
                            style={{ color: 'gold' }}
                          />
                        ) : licensing.icon === 'close' ? (
                          <Close fontSize="large" style={{ color: 'red' }} />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}{' '}
                    </Typography>
                  </HtmlTooltip>
                </Box>
              </Box>
              <Box
                mb={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{
                  borderLeft: '5px solid',
                  borderLeftColor: financial.color,
                }}
              >
                <Box display="flex" flexDirection="column" marginLeft="10px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <Typography variant="subtitle2" color="secondary">
                      {financial.label}
                    </Typography>
                    <Box display="flex">
                      <Box display="flex" alignItems="bottom">
                        <Typography variant="caption">
                          {' '}
                          Financial Statements
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      onDownload(
                        buyerBCOData.financials.fileType,
                        buyerBCOData.financials.blobUri
                      )
                    }}
                    color="secondary"
                  >
                    <GetApp style={{ color: financial.color }} />
                  </IconButton>
                </Box>
                <Box mr={2}>
                  <HtmlTooltip
                    title={
                      <BCOTooltip
                        title="Financial Statements"
                        bcoTypes={hardCodeData.getFinancialStatements()}
                      />
                    }
                  >
                    <Typography>
                      {financial.icon ? (
                        financial.icon === 'check' ? (
                          <Check fontSize="large" color="primary" />
                        ) : financial.icon === 'arrows-swap' ? (
                          <SwapHoriz
                            fontSize="large"
                            style={{ color: 'gold' }}
                          />
                        ) : financial.icon === 'close' ? (
                          <Close fontSize="large" style={{ color: 'red' }} />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}{' '}
                    </Typography>
                  </HtmlTooltip>
                </Box>
              </Box>
              <Box
                mb={3}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box display="flex">
                  <Box display="flex" alignItems="bottom">
                    <Typography variant="caption">
                      {' '}
                      What do these icons mean?
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <HtmlTooltip
                      title={
                        <span>
                          <p>
                            {' '}
                            These indicate a{' '}
                            {<Check fontSize="inherit" color="primary" />}{' '}
                            positive,{' '}
                            {
                              <SwapHoriz
                                fontSize="inherit"
                                style={{ color: 'gold' }}
                              />
                            }{' '}
                            neutral, or{' '}
                            {
                              <Close
                                fontSize="inherit"
                                style={{ color: 'red' }}
                              />
                            }{' '}
                            negative for the specific metric. <br />
                            Hover over any icon for more information.{' '}
                          </p>
                        </span>
                      }
                    >
                      <Typography>
                        <InfoOutlined fontSize="inherit" />
                      </Typography>
                    </HtmlTooltip>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  mb={4}
                  ml={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h1" style={{ fontWeight: 500 }}>
                    {buyerBCOData.bcoBuyerType}
                  </Typography>
                  <Box display="flex">
                    <Box display="flex" alignItems="bottom">
                      <Typography variant="caption"> Buyer Type</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  mb={4}
                  ml={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="h1" style={{ fontWeight: 500 }}>
                    {buyerBCOData.insurance_CoverageAmount !== null
                      ? getCountryCurrency(country)
                      : ''}
                    {buyerBCOData.insurance_CoverageAmount !== null
                      ? maskMoney(buyerBCOData.insurance_CoverageAmount)
                      : 'N/A'}
                  </Typography>
                  <Box display="flex">
                    <Box display="flex" alignItems="bottom">
                      <Typography variant="caption">
                        {countryCode === 'UK'
                          ? 'Professional Indemnity Insurance Coverage Amount'
                          : 'E&O Coverage Amount'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  mb={4}
                  ml={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                    Compliance Notes on Buyer
                  </Typography>
                  <Box display="flex">
                    <Box display="flex" alignItems="bottom">
                      <Typography variant="caption">
                        {' '}
                        {buyerBCOData.bcoComplianceNotes}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{
              minHeight: '5rem',
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            <Box display="flex" justifyContent="center">
              <InfoOutlined fontSize="small" />
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="caption"> BCO Disclaimer</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            style={{
              minHeight: '5rem',
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            <Box display="flex" justifyContent="flex-start" fontSize="0.6rem">
              Company understands that Buyer Due Diligence documentation is
              provided by Consultant without representation, warranty, or
              guarantee. Company understands that it is Company’s sole
              responsibility to review the due diligence material provided and
              perform its own independent analysis. In no event shall Consultant
              be liable for the representations, statements or material provided
              by a Buyer or prospective Buyer.
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DefaultDialog>
  )
}

export default BCOPreview
